<script setup>
import { inject } from 'vue';
import Card from '@/Components/Card/Card.vue';
import IconCheckCircle from '@/Components/Icons/IconCheckCircle.vue';
import IconCancel from '@/Components/Icons/IconCancel.vue';

const result = inject('score');

const records = (() => {
  const nsRecordExists = !!result.records?.ns?.score;
  const mxRecordExists = !!result.records?.mx?.score;
  const aRecordExists = !!result.records?.a?.score;

  return [
    {
      title: 'NS Record',
      exists: nsRecordExists,
      message: nsRecordExists
        ? `DNS Provider - ${result.info.dns_provider}`
        : 'No DNS presence found'
    },
    {
      title: 'MX Record',
      exists: mxRecordExists,
      message: mxRecordExists
        ? `Email Provider - ${result.records.mx.mail_provider}`
        : 'Not an email sending domain'
    },
    {
      title: 'A Record',
      exists: aRecordExists,
      message: aRecordExists
        ? 'Website presence found'
        : 'No website presence found'
    }
  ];
})();
</script>

<template>
  <div>
    <div class="flex items-start gap-3">
      <h5 class="flex items-center gap-2 text-lg font-bold text-white">
        Common Records
      </h5>
    </div>
    <Card class="bg-white-opacity mt-4">
      <div
        v-for="(record, index) in records"
        :key="index"
        class="mb-2 flex flex-col items-start gap-2 text-sm last:mb-0 md:flex-row md:items-center md:gap-0"
      >
        <div class="font-bold md:w-1/6">{{ record.title }}</div>
        <div class="flex items-center">
          <div v-tooltip="record.exists ? 'Record Found' : 'Not Found'">
            <IconCheckCircle
              v-if="record.exists"
              :size="20"
              class="mr-2 shrink-0 text-parakeet-500"
            />
            <IconCancel
              v-else
              :size="20"
              class="mr-2 shrink-0 text-white-smoke-500"
            />
          </div>
          {{ record.message }}
        </div>
      </div>
    </Card>
  </div>
</template>
