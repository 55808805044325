<script setup>
import CardLabel from '@/Components/Card/CardLabel.vue';
import Card from '@/Components/Card/Card.vue';
import AuthResultsResult from '@/Pages/HeaderAnalysis/Partials/AuthResultsResult.vue';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';
import { ToggleTracker } from '@/Common/utils.js';
import ARCDetail from '@/Pages/HeaderAnalysis/Partials/ARCDetail.vue';

defineProps({
  arcResults: Array
});

const toggler = new ToggleTracker();
</script>

<template>
  <div id="authenticated-receive-chain">
    <CardLabel label="Authenticated Receive Chain" />
    <Card>
      <div v-if="0 === arcResults.length">No Results</div>
      <div v-else class="flex flex-col gap-2">
        <div class="flex">
          <div class="w-1/12 font-bold">Instance</div>
          <div class="w-3/12 font-bold">System Identifier</div>
          <div class="w-2/12 font-bold">CV Status</div>
          <div class="w-5/12 font-bold">Results</div>
          <div class="w-1/12"><!-- action col --></div>
        </div>
        <div class="flex flex-col gap-4">
          <template v-for="(instance, index) in arcResults" :key="index">
            <div class="flex cursor-pointer" @click="toggler.toggle(index)">
              <div class="w-1/12">{{ instance.instance }}</div>
              <div class="w-3/12">{{ instance.seal.system_identifier }}</div>
              <div class="w-2/12">
                <AuthResultsResult
                  :result="instance.seal.chain_validation_status"
                />
              </div>
              <div class="grid w-5/12 grid-cols-4 gap-x-2">
                <AuthResultsResult
                  v-for="(result, idx) in instance.results.results"
                  :key="idx"
                  :result="result.result"
                  :label="result.method"
                />
              </div>
              <div class="flex w-1/12 grow justify-end">
                <IconExpandCircleDown
                  class="text-navy-200 transition-transform duration-300"
                  :class="{ '-rotate-180': toggler.isToggled(index) }"
                />
              </div>
            </div>
            <ARCDetail
              v-if="toggler.isToggled(index)"
              :arc-instance="instance"
              class="col-span-5"
            />
          </template>
        </div>
      </div>
    </Card>
  </div>
</template>
