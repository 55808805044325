<script setup>
import DomainAnalysisForm from '@/Pages/DomainAnalysis/Partials/DomainAnalysisForm.vue';
import SiteLayout from '@/Layouts/Layout.vue';
import ExplainDmarc from '@/Pages/DomainAnalysis/Partials/ExplainDmarc.vue';
import ExplainSpf from '@/Pages/DomainAnalysis/Partials/ExplainSpf.vue';
import ExplainDkim from '@/Pages/DomainAnalysis/Partials/ExplainDkim.vue';
import { provide } from 'vue';
import ExplainDomain from '@/Pages/DomainAnalysis/Partials/ExplainDomain.vue';
import ExplainTlsRpt from '@/Pages/DomainAnalysis/Partials/ExplainTlsRpt.vue';
import ExplainMtaSts from '@/Pages/DomainAnalysis/Partials/ExplainMtaSts.vue';
import ExplainBimi from '@/Pages/DomainAnalysis/Partials/ExplainBimi.vue';
import Card from '@/Components/Card/Card.vue';
import Alert from '@/Components/Alert.vue';

const props = defineProps({
  domain: String,
  result: Object
});

provide('domain', props.domain);
provide('result', props.result);

const hasResults = !props.result.hasOwnProperty('detail');
</script>

<template>
  <SiteLayout title="Domain Analysis">
    <DomainAnalysisForm :domain="domain" />
    <Card v-if="!hasResults">
      <Alert title="Record not found" variant="danger">
        {{ result.detail }}
      </Alert>
    </Card>

    <div v-if="hasResults" class="flex flex-col gap-2">
      <div class="text-lg font-bold md:text-xl">Impersonation</div>
      <ExplainDmarc id="dmarc" />
      <ExplainSpf id="spf" />
      <ExplainDkim id="dkim" />
    </div>

    <div v-if="hasResults" class="flex flex-col gap-2">
      <div class="text-lg font-bold md:text-xl">Privacy</div>
      <ExplainTlsRpt id="tls" />
      <ExplainMtaSts id="mta" />
    </div>

    <div v-if="hasResults" class="flex flex-col gap-2">
      <div class="text-lg font-bold md:text-xl">Branding</div>
      <ExplainBimi id="bimi" />
    </div>
    <ExplainDomain v-if="hasResults" />
  </SiteLayout>
</template>
