export default function getRoute(namespace, params = []) {
  const domainParam = route().params?.domain;

  if (domainParam) {
    params.push(domainParam);
    return route(`${namespace}.show`, params);
  }

  return route(`${namespace}.index`, params);
}
