<script setup>
import Card from '@/Components/Card/Card.vue';
import ExpandablePill from '@/Components/ExpandablePill.vue';
import StatusPill from '@/Pages/Common/StatusPill.vue';
import { computed, inject, provide, reactive } from 'vue';
import Preloader from '@/Components/Preloader.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import { Link } from '@inertiajs/vue3';
import axios from 'axios';
import SPFDirectiveMessaging from '@/Common/Classifiers/SPFDirectiveMessaging.js';
import ActionType from '@/Common/Enum/ActionType.js';
import { SPF as SPFTags } from '@/Common/Classifiers/TagMaps.js';
import { NIGHTCRAWLER_CHECK_STATUS } from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';
import Alert from '@/Components/Alert.vue';
import ExplainTitle from '@/Pages/DomainAnalysis/Partials/ExplainTitle.vue';
import ExplainDirectiveCard from '@/Pages/DomainAnalysis/Partials/ExplainDirectiveCard.vue';
import ExplainRecordValue from '@/Pages/DomainAnalysis/Partials/ExplainRecordValue.vue';
import ExplainDirectivePart from '@/Pages/DomainAnalysis/Partials/ExplainDirectivePart.vue';
import RedirectPresent from '@/Pages/DomainAnalysis/Partials/RedirectPresent.vue';
import SpfExplainDirective from '@/Pages/DomainAnalysis/Partials/SpfExplainDirective.vue';
const domain = inject('domain');
const result = inject('result');
const record = result.records.spf;

const directiveTitles = {
  v: 'Version',
  include: 'Include Additional SPF',
  ip4: 'Include IPv4 Range',
  ip6: 'Include IPv6 Range',
  a: 'A/AAAA Record',
  mx: 'MX Record',
  redirect: 'Redirect',
  exists: 'Exists',
  aspf: 'SPF Alignment Mode',
  all: 'All Directive'
};

const state = reactive({
  result: null
});

const expand = async () => {
  if (!state.result) {
    state.result = (
      await axios.get(route('explain.show', ['spf', domain]))
    ).data;
  }
};

const directives = computed(() => {
  return state.result?.directives;
});

const noMultipleRecords = result.records.spf.entries.length < 2;
const spfTags = Array.from(SPFTags.map(record.tags).values()).filter(
  (t) => t?.pill != null
);

const rootError = computed(() => {
  if (
    state.result &&
    [
      NIGHTCRAWLER_CHECK_STATUS.ERROR,
      NIGHTCRAWLER_CHECK_STATUS.FATAL,
      NIGHTCRAWLER_CHECK_STATUS
    ].includes(state.result.status)
  ) {
    return SPFDirectiveMessaging.get(
      '_root',
      NIGHTCRAWLER_CHECK_STATUS.ERROR,
      state.result.checks,
      null
    );
  }

  return null;
});

provide('directives', directives);
</script>

<template>
  <Card>
    <ExplainTitle :pills="spfTags" title="SPF">
      <StatusPill
        v-if="noMultipleRecords && record.num_lookup"
        :action-type="ActionType.INFO"
      >
        {{ record.num_lookup }} Lookups
      </StatusPill>
      <template #after>
        <RedirectPresent v-if="record.redirected_to" />
      </template>
    </ExplainTitle>

    <div
      v-if="result.impersonation.has_spf_record"
      class="mt-2 flex flex-col gap-4"
    >
      <ExpandablePill
        v-for="recordValue in result.records.spf.entries"
        :key="recordValue"
        :expandable="noMultipleRecords"
        @click="expand"
      >
        <template #default="{ expanded }">
          <ExplainRecordValue
            :status="record.status"
            :record-value="recordValue"
            :expanded="expanded"
          />
        </template>

        <template #expandable>
          <div v-if="rootError" class="mt-6 px-5">
            <Alert
              :variant="ActionType.ACTION_REQUIRED.color"
              title="Record Contains Errors"
            >
              <div
                v-for="(error, index) in rootError.errorMessages"
                :key="index"
              >
                {{ error }}
              </div>
            </Alert>
          </div>
          <div v-else>
            <ExplainDirectiveCard>
              <Preloader
                v-if="!state.result"
                :rows="Math.max(3, record?.record_parts?.length ?? 5)"
              />
              <SpfExplainDirective
                v-for="(d, i) in directives"
                v-else
                :key="i"
                :title="directiveTitles[d.directive]"
                :directive="d"
              />
            </ExplainDirectiveCard>
            <div class="flex flex-col gap-2 p-4 text-sm md:px-8">
              <ExplainDirectivePart
                v-if="record.redirected_to"
                title="Redirect"
                :directive="{
                  checks: [],
                  value: record.redirected_to
                }"
              >
                <template #detail>
                  -
                  <a
                    class="text-sm text-blue-600 underline hover:text-blue-500"
                    :href="route('explain.show', ['spf', domain])"
                  >
                    Lookup SPF
                  </a>
                </template>
              </ExplainDirectivePart>
            </div>
          </div>
          <Link
            v-if="state.result"
            :href="route('explain.show', ['spf', domain])"
          >
            <PrimaryButton
              class="mt-6"
              @click="route('explain.show', ['spf', domain])"
            >
              View SPF Tree
            </PrimaryButton>
          </Link>
        </template>
      </ExpandablePill>
    </div>
  </Card>
</template>
