<script setup>
import TextInput from '@/Components/Input/TextInput.vue';
import { reactive } from 'vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import isCidr from 'is-cidr';
import ipRangeCheck from 'ip-range-check';
import Card from '@/Components/Card/Card.vue';
import isValidIP from '@/Common/IsValidIp.js';
import Alert from '@/Components/Alert.vue';

const form = reactive({
  ip: '',
  cidr: ''
});

const state = reactive({
  ipAddress: '',
  cidr: '',
  valid: false,
  ipInRange: false
});

function submit() {
  state.ipAddress = form.ip;
  state.cidr = form.cidr;
  state.valid = Boolean(isValidIP(state.ipAddress) && isCidr(state.cidr));
  state.ipInCidr = state.valid && ipRangeCheck(state.ipAddress, state.cidr);
}
</script>

<template>
  <form
    class="flex flex-grow flex-col gap-4 md:flex-row md:items-center"
    @submit.prevent
  >
    <TextInput
      v-model.trim="form.ip"
      size="large"
      label="IP Address"
      description="e.g. 127.0.0.1"
      placeholder="Enter IP Address"
      @input="state.valid = false"
    />
    <TextInput
      v-model.trim="form.cidr"
      size="large"
      label="CIDR"
      description="e.g. 127.0.0.1/15"
      placeholder="Enter CIDR"
      @input="state.valid = false"
    />
    <div class="flex items-center md:mt-2">
      <PrimaryButton size="large" @click="submit">Check</PrimaryButton>
    </div>
  </form>

  <Teleport v-if="state.valid" to="#results">
    <Card>
      <Alert v-if="state.ipInCidr" title="IP Address Found" variant="success">
        The IP address ({{ state.ipAddress }}) is found in the given range ({{
          state.cidr
        }})
      </Alert>
      <Alert v-else title="IP Address not Found" variant="danger">
        The IP address ({{ state.ipAddress }}) was not found in the given range
        ({{ state.cidr }})
      </Alert>
    </Card>
  </Teleport>
</template>
