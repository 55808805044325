<script setup>
defineProps({
  variant: {
    type: String,
    default: 'default'
  }
});

const classes = {
  warning: 'text-dirty-orange-600',
  danger: 'text-red-600',
  default: 'text-cool-grey-900'
};
</script>

<template>
  <div :class="classes[variant]" class="mt-1 text-xs">
    <slot />
  </div>
</template>
