export class CyclopsTagMapper {
  constructor(tagmap) {
    this.tagmap = tagmap;
  }

  /**
   * @param {[{msg: string}]|(function(*): *)} tags
   */
  map(tags) {
    const out = new Map();
    for (const i in tags) {
      const val = this.tagmap[tags[i].msg];

      if (val) {
        out.set(tags[i].msg, { ...val, severity: tags[i].level });
      }
    }

    return out;
  }

  /**
   * @param {Array} searchTags
   * @return {Object}
   */
  firstOf(searchTags) {
    for (const [k, v] of this.tagmap) {
      if (searchTags.includes(k)) {
        return v;
      }
    }
  }
}

export default function cyclopsTagMap(map) {
  return new CyclopsTagMapper(map);
}
