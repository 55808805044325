<script setup>
const props = defineProps({
  score: Number,
  variant: {
    type: String,
    default: 'default',
    validator: (value) =>
      ['success', 'warning', 'danger', 'default'].includes(value)
  }
});

const classes = {
  success: 'bg-parakeet-500',
  warning: 'bg-dirty-orange-500',
  danger: 'bg-rio-red-500',
  default: 'bg-cool-grey-300'
}[props.variant];
</script>

<template>
  <div
    class="rounded-[20px] p-4 text-2xl font-bold text-white-smoke-500"
    :class="classes"
  >
    <div class="flex items-baseline whitespace-nowrap px-1">
      {{ score }}
      <span class="text-sm">/5</span>
    </div>
  </div>
</template>
