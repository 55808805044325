<script setup>
import Select from '@/Components/Select.vue';
import { reactive } from 'vue';
import getRoute from '@/Common/GetRoute.js';
import { router } from '@inertiajs/vue3';

const props = defineProps({
  selected: String
});

const state = reactive({
  lookup: props.selected
});

const lookups = {
  DNS: getRoute('lookup', ['dns']),
  DMARC: getRoute('lookup', ['dmarc']),
  SPF: getRoute('explain', ['spf']),
  DKIM: getRoute('explain', ['dkim']),
  'MTA-STS': getRoute('lookup', ['mta-sts']),
  'TLS-RPT': getRoute('lookup', ['tls-rpt'])
};

const redirect = () => {
  router.visit(lookups[state.lookup]);
};
</script>

<template>
  <Select v-model="state.lookup" size="large" label="Lookup" @change="redirect">
    <option
      v-for="(route, lookup) in lookups"
      :key="lookup"
      :value="lookup"
      v-text="lookup"
    />
  </Select>
</template>
