<script setup>
import RiskSquareScore from '@/Pages/DomainScore/Partials/RiskSquareScore.vue';
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import DmarcCard from '@/Pages/DomainScore/Partials/DetailedReport/Impersonation/DmarcCard.vue';
import SpfCard from '@/Pages/DomainScore/Partials/DetailedReport/Impersonation/SpfCard.vue';
import DkimCard from '@/Pages/DomainScore/Partials/DetailedReport/Impersonation/DkimCard.vue';
import { inject } from 'vue';
import ACTION_TYPES, {
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';
import Help from '@/Components/Help.vue';

const result = inject('score');
const actionType = getActionTypeFromScore(result.impersonation.score);
</script>

<template>
  <div>
    <div class="flex items-start gap-3">
      <div class="flex flex-col items-center">
        <RiskSquareScore :score="result.impersonation.score" />
        <RiskLabel
          :score="result.impersonation.score"
          class="mt-1 text-nowrap text-sm"
        >
          Risk
        </RiskLabel>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="flex items-center gap-2 text-lg font-bold text-white">
          Impersonation Rating
          <Help
            text="Your impersonation rating looks at the likelihood of your organisation’s domain being used in impersonation attacks. There are a few settings in your DNS records which can help to prevent such attacks and we check to see if they have been setup correctly."
          />
        </h5>
        <p v-if="actionType === ACTION_TYPES.NO_ACTION">
          Your organization has very low risk of your name being used by
          criminals in fraudulent email activities. Domains with a score of 5
          are 100% DMARC compliant and are protected from impersonation and
          phishing attacks.
        </p>
        <p v-else-if="actionType === ACTION_TYPES.ACTION_RECOMMENDED">
          Your domain configuration requires one more change. Deliverability of
          legitimate email may be disrupted and some messages may land in Spam
          or Junk folders. Savvy cybercriminals could impersonate your domain.
        </p>
        <p v-else>
          There is little or no protection at all of your domain, with the
          highest probability that your domain and therefore your company name
          may be hijacked by criminals and used in fraudulent email activities.
        </p>
      </div>
    </div>
    <div class="mt-4 flex flex-col gap-2 md:gap-4">
      <DmarcCard />
      <SpfCard />
      <DkimCard />
    </div>
  </div>
</template>
