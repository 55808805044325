<script setup>
import { getActionTypeFromScore } from '@/Common/Enum/ActionType.js';
import DarkFlaredCard from '@/Components/Card/DarkFlaredCard.vue';

const props = defineProps({
  score: Number
});

const variant = getActionTypeFromScore(props.score).color;
</script>

<template>
  <DarkFlaredCard :variant="variant">
    <slot />
  </DarkFlaredCard>
</template>
