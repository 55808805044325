import './bootstrap';
import '../css/app.css';
import '../css/tooltip.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import { sentrySetup } from '@/Common/SentrySetup.js';
import { tooltipSetup } from '@/Components/Tooltip.js';
import { redirectLegacyUrl } from '@/legacy-redirects.js';

const appName = 'Sendmarc';
const urlSearchParams = new URLSearchParams(window.location.search);
const layout = urlSearchParams.has('layout')
  ? urlSearchParams.get('layout')
  : 'web';

!redirectLegacyUrl() &&
  void createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
      const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
      return pages[`./Pages/${name}.vue`];
    },
    setup({ el, App, props, plugin }) {
      /** @type {import('../../vendor/tightenco/ziggy').Config} */
      const ziggyConfig = {
        ...props.initialPage.props.ziggy,
        location: new URL(props.initialPage.props.ziggy.location),
        url: props.initialPage.props.ziggy.location
      };

      const app = createApp({ render: () => h(App, props) })
        .use(plugin)
        .use(ZiggyVue, ziggyConfig);

      app.provide('layout', layout);
      tooltipSetup(app);
      sentrySetup(app);

      return app.mount(el);
    },
    progress: {
      color: '#0073EA'
    }
  });
