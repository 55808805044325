<script setup>
import Card from '@/Components/Card/Card.vue';
import CardLabel from '@/Components/Card/CardLabel.vue';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';
import AuthResultsResult from '@/Pages/HeaderAnalysis/Partials/AuthResultsResult.vue';
import { ToggleTracker } from '@/Common/utils.js';
import PropertyListItem from '@/Pages/HeaderAnalysis/Partials/PropertyListItem.vue';

defineProps({
  results: Array
});

const toggler = new ToggleTracker();

const canToggle = (auth) => Boolean(0 < Object.keys(auth.properties).length);
</script>

<template>
  <div id="authentication-results">
    <CardLabel label="Authentication Results" />
    <Card>
      <div v-if="0 === results.length">No Results</div>
      <div v-else class="flex flex-col gap-2">
        <div class="flex">
          <div class="w-1/6 font-bold">Method</div>
          <div class="w-1/6 font-bold">Result</div>
          <div class="font-bold">Comments</div>
        </div>
        <template v-for="(server, srv_idx) in results" :key="srv_idx">
          <template v-for="(auth, index) in server.results" :key="index">
            <div
              :class="{ 'cursor-pointer': canToggle(auth) }"
              class="flex items-center"
              @click="canToggle(auth) && toggler.toggle(srv_idx, index)"
            >
              <div class="w-1/6 shrink-0 uppercase">{{ auth.method }}</div>
              <AuthResultsResult :result="auth.result" class="w-1/6 shrink-0" />
              <div class="w-9/12">{{ auth.comments.join(', ') }}</div>
              <div class="flex w-1/12 flex-col">
                <IconExpandCircleDown
                  v-if="canToggle(auth)"
                  class="self-end text-navy-200 transition-transform duration-300"
                  :class="{ '-rotate-180': toggler.isToggled(srv_idx, index) }"
                />
              </div>
            </div>
            <Card
              v-if="toggler.isToggled(srv_idx, index)"
              class="col-span-4 !bg-white-smoke-500"
              :pad-bottom="true"
            >
              <div class="mb-2 font-bold">Properties</div>
              <PropertyListItem
                v-for="(val, prop) in auth.properties"
                :key="prop"
                :title-class="['w-1/3']"
                :title="prop"
                :value="val"
              />
            </Card>
          </template>
        </template>
      </div>
    </Card>
  </div>
</template>
