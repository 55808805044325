import * as Sentry from '@sentry/vue';

/**
 * @param {import('vue').App<Element>} app
 * @returns {import('vue').App<Element>}
 */
export const sentrySetup = (app) => {
  const {
    VITE_SENTRY_DSN_PUBLIC,
    VITE_SENTRY_TRACES_SAMPLE_RATE,
    VITE_APP_RELEASE
  } = import.meta.env;

  if (VITE_SENTRY_DSN_PUBLIC) {
    Sentry.init({
      app,
      dsn: VITE_SENTRY_DSN_PUBLIC,
      release: VITE_APP_RELEASE,
      sampleRate: VITE_SENTRY_TRACES_SAMPLE_RATE,
      trackComponents: true,
      autoSessionTracking: true
    });
  }

  return app;
};
