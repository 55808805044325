<script setup>
import { inject } from 'vue';
import Card from '@/Components/Card/Card.vue';
import ExplainDirectiveRow from '@/Pages/DomainAnalysis/Partials/ExplainDirectiveRow.vue';

const result = inject('result');
</script>

<template>
  <Card class="text-sm">
    <div class="flex flex-col gap-4">
      <ExplainDirectiveRow class="!gap-0">
        Authority Domain
        <template #detail>
          {{ result.records.soa.authority_domain }}
        </template>
      </ExplainDirectiveRow>

      <ExplainDirectiveRow class="!gap-0">
        Mail-Sending Domain
        <template #detail>
          {{ result.records.mx !== null ? 'Yes' : 'No' }}
        </template>
      </ExplainDirectiveRow>

      <div>
        <ExplainDirectiveRow class="!gap-0">
          DNS Provider
          <template #detail>
            {{ result.records.soa.dns_provider }}
          </template>
        </ExplainDirectiveRow>

        <Card
          v-if="result.records?.ns?.nameservers?.length ?? 0 > 0"
          class="mt-1 bg-white-smoke-500 !p-6"
        >
          <div class="mb-2 flex font-bold">
            <div class="w-1/6">Type</div>
            <div class="w-1/6">TTL</div>
            <div>Name Server</div>
          </div>
          <div
            v-for="(nameserver, index) in result.records.ns.nameservers"
            :key="index"
            class="mb-1 flex"
          >
            <div class="w-1/6">NS</div>
            <div class="w-1/6">{{ result.records.ns.ttl }}</div>
            <div>{{ nameserver }}</div>
          </div>
        </Card>
      </div>

      <div v-if="result.records.mx !== null">
        <ExplainDirectiveRow class="!gap-0">
          Email Provider
          <template #detail>
            {{ result.records.mx.mail_provider }}
          </template>
        </ExplainDirectiveRow>

        <Card
          v-if="result.records.mx.mailservers ?? 0 > 0"
          class="mt-1 bg-white-smoke-500 !p-6"
        >
          <div class="mb-2 flex font-bold">
            <div class="w-1/6">Pref</div>
            <div class="w-1/6">TTL</div>
            <div>Hostname</div>
          </div>
          <div
            v-for="(mailserver, index) in result.records.mx.mailservers"
            :key="index"
            class="mb-1 flex"
          >
            <div class="w-1/6">{{ mailserver.preference }}</div>
            <div class="w-1/6">{{ result.records.mx.ttl }}</div>
            <div>{{ mailserver.exchange }}</div>
          </div>
        </Card>
      </div>
      <ExplainDirectiveRow class="!gap-0">
        DNS Admin Email
        <template #detail>
          {{ result.records.soa.admin_email }}
        </template>
      </ExplainDirectiveRow>
    </div>
  </Card>
</template>
