<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import ActionType from '@/Common/Enum/ActionType.js';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';
import { API_STATUS } from '@/Common/Enum/ApiStatus.js';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';

const result = inject('score');

const tags =
  result.records.tlsrpt?.tags?.reduce((acc, tag) => {
    acc[tag.msg] = tag;
    return acc;
  }, {}) ?? {};
</script>

<template>
  <Card class="bg-white-opacity">
    <DetailedReportHeader
      title="TLS-RPT or SMTP TLS Reporting"
      description="Transport Layer Security Reporting"
      tooltip="Enables sending systems to share statistics and information about failures with recipient domains."
    />
    <div class="mt-5 flex flex-col">
      <ExplainScorePart
        v-if="!result.privacy.has_tlsrpt_record"
        title="DNS Record"
        :action-type="ACTION_TYPES.ACTION_REQUIRED"
      >
        No record found
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="tags.hasOwnProperty('Too many records')"
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        Multiple records found -
        <LinkDomainAnalysis :domain="result.domain" fragment="tls" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="
          [API_STATUS.FAIL, API_STATUS.FATAL].includes(
            result.records?.tlsrpt?.status
          )
        "
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        Invalid record found -
        <LinkDomainAnalysis :domain="result.domain" fragment="tls" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="tags.hasOwnProperty('errors')"
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        Record found with errors -
        <LinkDomainAnalysis :domain="result.domain" fragment="tls" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="tags.hasOwnProperty('http rua')"
        title="DNS Record"
        :action-type="ActionType.ACTION_RECOMMENDED"
      >
        Record found with HTTP in Reporting Address -
        <LinkDomainAnalysis :domain="result.domain" fragment="tls" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else
        title="DNS Record"
        :action-type="ActionType.NO_ACTION"
      >
        Record found without errors -
        <LinkDomainAnalysis :domain="result.domain" fragment="tls" />
      </ExplainScorePart>

      <div>
        <ExplainScorePart
          v-if="
            !result.records.tlsrpt.tlsrpt_providers.includes('Unknown') &&
            result.records.tlsrpt.tlsrpt_providers.length
          "
          title="Providers"
          :action-type="ACTION_TYPES.INFO"
        >
          {{ result.records.tlsrpt.tlsrpt_providers.join(', ') }}
        </ExplainScorePart>
        <ExplainScorePart
          v-else
          title="Providers"
          :action-type="ACTION_TYPES.INFO"
        >
          <div>
            None -
            <a
              href="https://sendmarc.com/contact/"
              class="underline"
              target="_blank"
            >
              Contact us
            </a>
            for assistance
          </div>
        </ExplainScorePart>
      </div>
    </div>
  </Card>
</template>
