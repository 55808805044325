<script setup>
import RiskSquareScore from '@/Pages/DomainScore/Partials/RiskSquareScore.vue';
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import { inject } from 'vue';
import BimiCard from '@/Pages/DomainScore/Partials/DetailedReport/Branding/BimiCard.vue';
import ACTION_TYPES, {
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';
import Help from '@/Components/Help.vue';

const result = inject('score');
const actionType = getActionTypeFromScore(result.marketing.score);
</script>

<template>
  <div>
    <div class="flex items-start gap-3">
      <div class="flex flex-col items-center">
        <RiskSquareScore :score="result.marketing.score" />
        <RiskLabel
          :score="result.marketing.score"
          class="mt-1 text-nowrap text-sm"
        >
          Risk
        </RiskLabel>
      </div>
      <div class="flex flex-col gap-2">
        <h5 class="flex items-center gap-2 text-lg font-bold text-white">
          Branding Rating
          <Help
            text="Your branding rating looks at whether your organization's domain branding has been setup correctly by looking at the BIMI record."
          />
        </h5>
        <p v-if="actionType === ACTION_TYPES.NO_ACTION">
          Your organization effectively uses branding to build trust through
          emails. Your logo and verified sender mark will be visible to
          recipients in email clients that support BIMI.
        </p>
        <p v-else-if="actionType === ACTION_TYPES.ACTION_RECOMMENDED">
          Your email branding configuration requires some attention. Your logo
          and verified sender mark may display in certain email clients but most
          require a fully implemented BIMI record to display branding.
        </p>
        <p v-else>
          You have not effectively implemented email inbox branding for your
          domain. Branding builds trust and assures recipients that an email is
          authentic. We recommend that you fully implement BIMI for better
          recognition.
        </p>
      </div>
    </div>
    <BimiCard />
  </div>
</template>
