<script setup>
import { computed } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'default'
  }
});

const classes = computed(() => {
  return {
    default:
      'text-cool-grey-800 bg-cool-grey-500/15 dark:bg-cool-grey-shade-dark-50 dark:text-cool-grey-400',
    success:
      'text-parakeet-500 bg-parakeet-500/10 dark:bg-parakeet-shade-dark-40 dark:text-parakeet-300',
    warning:
      'text-dirty-orange-500 bg-dirty-orange-500/10 dark:bg-dirty-orange-shade-dark-40 dark:text-dirty-orange-300',
    danger:
      'text-rio-red-500 bg-rio-red-500/10 dark:bg-rio-red-shade-dark-50 dark:text-rio-red-200',
    info: 'text-blue-500 bg-blue-500/10 dark:bg-blue-shade-dark-50 dark:text-blue-200'
  }[props.variant];
});
</script>

<template>
  <div
    class="inline-block rounded-full px-3 py-1 text-center text-xs"
    :class="classes"
    style="text-wrap: nowrap"
  >
    <slot />
  </div>
</template>
