<script setup>
import { getActionTypeFromScore } from '@/Common/Enum/ActionType.js';

const props = defineProps({
  score: Number
});

const rating = {
  success: {
    class: 'text-parakeet-500',
    label: 'Low'
  },
  warning: {
    class: 'text-dirty-orange-500',
    label: 'Moderate'
  },
  danger: {
    class: 'text-rio-red-500',
    label: 'High'
  }
}[getActionTypeFromScore(props.score).color];
</script>

<template>
  <h2 :class="rating.class" class="font-bold">
    {{ rating.label }}
    <slot />
  </h2>
</template>
