<script setup>
import OutlinedButton from '@/Components/Button/OutlinedButton.vue';

const redirect = () => {
  window.open('https://sendmarc.com/contact/', '_blank');
};
</script>

<template>
  <OutlinedButton class="mt-5 md:mt-0" size="large" @click="redirect">
    Contact Us
  </OutlinedButton>
</template>
