<script setup>
const props = defineProps({
  value: String,
  size: {
    type: String,
    default: 'sm'
  }
});

const classes = {
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-xl',
  '3xl': 'text-3xl'
}[props.size];
</script>

<template>
  <label class="mb-1 block" :class="classes">
    <span v-if="value">
      {{ value }}
    </span>
    <span v-else>
      <slot />
    </span>
  </label>
</template>
