const mapping = {
  '#/domain-analysis': '/domain-analysis',
  '#/email-analysis': '/header-analysis',
  '#/spf-analysis': '/explain/spf',
  '#/dkim-analysis': '/explain/dkim',
  '#/cname-analysis': '/lookup/dns',
  '#/domain-lookup': '/lookup/dns'
};

export function redirectLegacyUrl() {
  const url = new URL(window.location);
  if (url.pathname === '/' && url.hash) {
    const dest = mapping[url.hash];
    if (dest) {
      window.location.hash = '';
      window.location.pathname = dest;
      return true;
    }
  }

  return false;
}
