<script setup>
import CardLabel from '@/Components/Card/CardLabel.vue';
import Card from '@/Components/Card/Card.vue';
import { ToggleTracker } from '@/Common/utils.js';
import PropertyListItem from '@/Pages/HeaderAnalysis/Partials/PropertyListItem.vue';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';
import { formatProperty } from '@/Pages/HeaderAnalysis/header-analysis-utils.js';

defineProps({
  dkimSignatures: Array
});

const toggler = new ToggleTracker();
const propTitle = (prop) => prop.replace('_', ' ');
</script>

<template>
  <div>
    <CardLabel label="DKIM Signatures" />
    <Card>
      <div v-if="0 === dkimSignatures.length">No Results</div>
      <div v-else class="flex flex-col gap-4">
        <div class="flex">
          <div class="w-4/12 font-bold">Selector</div>
          <div class="w-7/12 font-bold">System Identifier</div>
          <div class="w-1/12"><!-- action col --></div>
        </div>
        <template v-for="(sig, index) in dkimSignatures" :key="index">
          <div class="flex cursor-pointer" @click="toggler.toggle(index)">
            <div class="w-4/12">{{ sig.selector }}</div>
            <div class="w-7/12">{{ sig.system_identifier }}</div>
            <div class="flex w-1/12 flex-col">
              <IconExpandCircleDown
                class="self-end text-navy-200 transition-transform duration-300"
                :class="{ '-rotate-180': toggler.isToggled(index) }"
              />
            </div>
          </div>
          <Card
            v-if="toggler.isToggled(index)"
            class="!bg-white-smoke-500"
            :pad-bottom="true"
          >
            <div class="flex flex-col gap-1">
              <div class="font-bold">Properties</div>
              <PropertyListItem
                v-for="(val, prop, idx) in sig"
                :key="idx"
                :title="propTitle(prop)"
                :value="formatProperty(prop, val)"
                :title-class="['w-1/4', 'shrink-0', 'capitalize']"
                :hide-nulls="true"
              />
            </div>
          </Card>
        </template>
      </div>
    </Card>
  </div>
</template>
