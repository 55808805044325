<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import Card from '@/Components/Card/Card.vue';
import SpfTree from '@/Pages/SpfPolicyTest/Partials/SpfTree.vue';
import LookupForm from '@/Pages/Lookup/Partials/LookupForm.vue';
import { router } from '@inertiajs/vue3';
import Alert from '@/Components/Alert.vue';

defineProps({
  domain: {
    type: String,
    default: ''
  },
  result: {
    type: Object,
    default: null
  }
});

const submit = (domain) => {
  router.visit(route('explain.show', ['spf', domain]));
};
</script>

<template>
  <SiteLayout title="SPF Lookup Tool">
    <Card>
      <LookupForm lookup-type="SPF" :domain="domain" @submitted="submit" />
    </Card>
    <Card v-if="result && result.hasOwnProperty('detail')">
      <Alert variant="danger" title="Record not found">
        {{ result.detail }}
      </Alert>
    </Card>
    <Card v-if="result?.summary">
      <div class="flex flex-col gap-2">
        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">Status</div>
          <div class="text-sm">{{ domain }}</div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">All</div>
          <div class="text-sm">
            {{ result.summary.all_qualifier_name }} ({{
              result.summary.all_qualifier
            }})
          </div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">DNS lookups</div>
          <div class="text-sm">{{ result.summary.num_lookup }}</div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">MX lookups</div>
          <div class="text-sm">{{ result.summary.num_secondary_lookup }}</div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">Void lookups</div>
          <div class="text-sm">{{ result.summary.num_void_lookup }}</div>
        </div>

        <div class="flex items-center justify-between md:justify-start">
          <div class="text-sm font-bold md:w-3/12">Root record size</div>
          <div class="text-sm">{{ result.summary.root_record_size }}</div>
        </div>
      </div>
      <Card v-if="result?.spf_tree" class="mt-8 bg-white-smoke-500">
        <div class="treeview js-treeview">
          <ul>
            <li>
              <SpfTree :node="result.spf_tree" />
            </li>
          </ul>
        </div>
      </Card>
    </Card>
  </SiteLayout>
</template>
