<script setup>
import IconCheckCircle from '@/Components/Icons/IconCheckCircle.vue';
import IconError from '@/Components/Icons/IconError.vue';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  variant: {
    type: String,
    default: 'default'
  }
});

const selectedVariant = {
  default: 'bg-cool-grey-100 text-cool-grey-600 ',
  success: 'bg-parakeet-100 text-parakeet-600',
  warning: 'bg-dirty-orange-100 text-dirty-orange-600 ',
  danger: 'bg-rio-red-100 text-rio-red-600 ',
  info: 'bg-blue-100 text-blue-600 '
}[props.variant];
</script>

<template>
  <div
    :class="selectedVariant"
    class="flex items-center gap-2 rounded-xl px-4 py-3"
  >
    <span>
      <IconCheckCircle v-if="variant === 'success'" :size="35" />
      <IconError v-else :size="35" />
    </span>
    <div class="flex flex-col">
      <div class="font-haas-medium text-sm font-semibold">
        {{ title }}
      </div>
      <div class="text-sm">
        <slot />
      </div>
    </div>
  </div>
</template>
