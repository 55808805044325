<script setup>
import RiskSquareScore from '@/Pages/DomainScore/Partials/RiskSquareScore.vue';
import RiskLabel from '@/Pages/DomainScore/Partials/RiskLabel.vue';
import { inject } from 'vue';
import TlsCard from '@/Pages/DomainScore/Partials/DetailedReport/Privacy/TlsCard.vue';
import MtaStsCard from '@/Pages/DomainScore/Partials/DetailedReport/Privacy/MtaStsCard.vue';
import Help from '@/Components/Help.vue';
import ACTION_TYPES, {
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';

const result = inject('score');
const actionType = getActionTypeFromScore(result.privacy.score);
</script>

<template>
  <div class="flex items-start gap-3">
    <div class="flex flex-col items-center">
      <RiskSquareScore :score="result.privacy.score" />
      <RiskLabel :score="result.privacy.score" class="mt-1 text-nowrap text-sm">
        Risk
      </RiskLabel>
    </div>
    <div class="flex flex-col gap-2">
      <h5 class="flex items-center gap-2 text-lg font-bold text-white">
        Privacy Rating
        <Help
          text="Your privacy rating looks at whether your mail server providers has been setup correctly to receive TLS secure SMTP connections."
        />
      </h5>
      <p v-if="actionType === ACTION_TYPES.NO_ACTION">
        Your email communication is at low risk of being intercepted by or
        visible to cybercriminals. Your domain enforces effective encryption
        standards and you'll will receive reporting on enforcement of these
        measures.
      </p>
      <p v-else-if="actionType === ACTION_TYPES.ACTION_RECOMMENDED">
        Your enforcement of encryption for email communication requires
        attention. Your emails could be intercepted if sending isn't secured.
      </p>
      <p v-else>
        Your domain has minimal to no safeguards in place for email privacy,
        posing a high risk that your communications could be intercepted or
        compromised by unauthorized parties. While most senders encrypt
        communication by default, it is important to enforce policies for those
        that don't.
      </p>
    </div>
  </div>
  <div class="mt-4 flex flex-col gap-2 md:gap-4">
    <TlsCard />
    <MtaStsCard />
  </div>
</template>
