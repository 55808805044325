<script setup>
import TextInput from '@/Components/Input/TextInput.vue';
import { Netmask } from 'netmask';
import { reactive } from 'vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import Card from '@/Components/Card/Card.vue';

const state = reactive({
  cidr: '',
  result: null
});

const submit = () => {
  const block = new Netmask(state.cidr);

  state.result = {
    'CIDR Range': state.cidr,
    'CIDR Base': block.base,
    Netmask: block.mask,
    Bitmask: block.bitmask,
    Host: block.hostmask,
    Broadcast: block.broadcast,
    'Block Size': block.size,
    'First IP': block.first,
    'Last IP': block.last
  };
};
</script>

<template>
  <form
    class="flex flex-grow flex-col gap-4 md:flex-row md:items-center"
    @submit.prevent="submit"
  >
    <TextInput
      v-model.trim="state.cidr"
      size="large"
      description="e.g. 127.0.0.1/15"
      label="CIDR"
      placeholder="Enter CIDR"
      @input="state.result = null"
    />
    <div class="flex items-center md:mt-2">
      <PrimaryButton size="large" @click="submit">Calculate</PrimaryButton>
    </div>
  </form>

  <Teleport v-if="state.valid !== null" to="#results">
    <Card>
      <div class="flex flex-col gap-4">
        <div
          v-for="(value, key) in state.result"
          :key="key"
          class="flex items-end justify-between md:justify-start"
        >
          <div class="w-3/12 font-bold">
            {{ key }}
          </div>
          <div>
            {{ value }}
          </div>
        </div>
      </div>
    </Card>
  </Teleport>
</template>
