<script setup>
import Card from '@/Components/Card/Card.vue';
import ExpandablePill from '@/Components/ExpandablePill.vue';
import { computed, inject, reactive } from 'vue';
import Preloader from '@/Components/Preloader.vue';
import axios from 'axios';
import ExplainTitle from '@/Pages/DomainAnalysis/Partials/ExplainTitle.vue';
import ExplainDirectivePart from '@/Pages/DomainAnalysis/Partials/ExplainDirectivePart.vue';
import ExplainDirectiveCard from '@/Pages/DomainAnalysis/Partials/ExplainDirectiveCard.vue';
import { MTASTS as MtaStsTags } from '@/Common/Classifiers/TagMaps.js';
import ExplainRecordValue from '@/Pages/DomainAnalysis/Partials/ExplainRecordValue.vue';

const domain = inject('domain');
const result = inject('result');
const record = result.records.mtasts;

const state = reactive({
  result: null
});

const expand = async () => {
  if (!state.result) {
    state.result = (
      await axios.get(route('explain.show', ['mta-sts', domain]))
    ).data;
  }
};

const directives = computed(() => {
  return (
    state.result?.directives.reduce((acc, directive) => {
      acc[directive.directive] = directive;
      return acc;
    }, {}) ?? {}
  );
});

const mtaStsTags = Array.from(MtaStsTags.map(record.tags).values()).filter(
  (t) => t?.pill != null
);
</script>

<template>
  <Card>
    <ExplainTitle :pills="mtaStsTags" title="MTA-STS" />
    <div
      v-if="result.privacy.has_mtasts_record"
      class="mt-2 flex flex-col gap-4"
    >
      <ExpandablePill
        v-for="recordValue in result.records.mtasts.entries"
        :key="recordValue"
        :expandable="result.records.mtasts.entries.length < 2"
        @click="expand"
      >
        <ExplainRecordValue
          :status="record.status"
          :record-value="recordValue"
        />
        <template #expandable>
          <ExplainDirectiveCard
            v-if="!state.result && !Object.keys(directives).length > 0"
          >
            <Preloader :rows="5" />
          </ExplainDirectiveCard>
          <div v-else>
            <ExplainDirectiveCard>
              <ExplainDirectivePart title="Version" :directive="directives.v" />
              <ExplainDirectivePart
                title="Identity"
                :directive="directives.id"
              />
            </ExplainDirectiveCard>

            <div class="mt-4 pl-6 font-bold md:pl-8">Policy</div>
            <ExplainDirectiveCard>
              <ExplainDirectivePart title="Mode" :directive="directives.mode" />
              <ExplainDirectivePart
                title="Max age"
                :directive="directives.max_age"
              />
              <ExplainDirectivePart
                v-for="(directive, index) in state.result.directives.filter(
                  (d) => d.directive === 'mx'
                )"
                :key="index"
                :directive="directive"
                title="MX"
              />
            </ExplainDirectiveCard>
          </div>
        </template>
      </ExpandablePill>
    </div>
  </Card>
</template>
