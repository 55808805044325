<script setup>
import { getActionTypeFromScore } from '@/Common/Enum/ActionType.js';
import Pill from '@/Components/Pill.vue';

const props = defineProps({
  score: Number,
  tag: String,
  variant: {
    type: [Object, null]
  }
});

const _variant = (
  props.variant ? props.variant : getActionTypeFromScore(props.score)
).color;
</script>

<template>
  <Pill :variant="_variant">
    {{ tag }}
  </Pill>
</template>
