<script setup>
defineProps({
  lookup: Object
});

const details = {
  TTL: 'ttl',
  'Registered Domain': 'full_domain',
  'Primary Nameserver': 'master_name',
  Admin: 'admin_email',
  Serial: 'serial',
  'Refresh TTL': 'refresh_ttl',
  'Retry TTL': 'retry_ttl',
  'Expire TTL': 'expire_ttl',
  'Minimum TTL': 'min_ttl'
};
</script>

<template>
  <div>
    <div class="mb-2 flex justify-between gap-2 md:justify-normal md:gap-0">
      <div class="font-bold md:w-2/12">RdType:</div>
      <div class="flex items-center gap-2">SOA</div>
    </div>
    <div
      v-for="(value, key) in details"
      :key="key"
      class="mb-2 flex justify-between gap-2 md:justify-normal md:gap-0"
    >
      <div class="font-bold md:w-2/12">{{ key }}:</div>
      <div class="flex items-center gap-2">{{ lookup[value] }}</div>
    </div>
  </div>
</template>
