<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import Card from '@/Components/Card/Card.vue';
import { reactive } from 'vue';
import { router } from '@inertiajs/vue3';
import DnsPreloader from '@/Pages/Lookup/Partials/Dns/DnsPreloader.vue';
import CardDetailMx from '@/Pages/Lookup/Partials/Dns/CardDetailMx.vue';
import CardDetailNs from '@/Pages/Lookup/Partials/Dns/CardDetailNs.vue';
import CardDetailSoa from '@/Pages/Lookup/Partials/Dns/CardDetailSoa.vue';
import CardDetailA from '@/Pages/Lookup/Partials/Dns/CardDetailA.vue';
import CardDetailTxt from '@/Pages/Lookup/Partials/Dns/CardDetailTxt.vue';
import CardDetailCname from '@/Pages/Lookup/Partials/Dns/CardDetailCname.vue';
import CardDetailCaa from '@/Pages/Lookup/Partials/Dns/CardDetailCaa.vue';
import CardDetailSrv from '@/Pages/Lookup/Partials/Dns/CardDetailSrv.vue';
import LookupForm from '@/Pages/Lookup/Partials/LookupForm.vue';
import CardLabel from '@/Components/Card/CardLabel.vue';
import Preloader from '@/Components/Preloader.vue';

defineProps({
  domain: {
    type: String,
    default: ''
  },
  result: {
    type: Object,
    default: null
  }
});

const state = reactive({
  isLoading: false,
  isRedirecting: false
});

const submit = (domain) => {
  state.isLoading = true;
  router.visit(route('lookup-dns.show', [domain]));
};

router.on('start', () => {
  state.isRedirecting = true;
});
</script>

<template>
  <SiteLayout title="DNS Lookup Tool">
    <Card>
      <LookupForm :domain="domain" @submitted="submit" />
    </Card>
    <DnsPreloader v-if="state.isLoading" />
    <Card v-else-if="state.isRedirecting">
      <Preloader :rows="4" />
    </Card>
    <div v-for="(lookup, key) in result" v-else :key="key">
      <CardLabel :label="key.toUpperCase()" />
      <Card>
        <div v-if="lookup.hasOwnProperty('detail')" class="italic">
          {{ lookup.detail }}
        </div>
        <CardDetailA v-else-if="key === 'a/aaaa'" :lookup="lookup" />
        <CardDetailTxt v-else-if="key === 'txt'" :lookup="lookup" />
        <CardDetailMx v-else-if="key === 'mx'" :lookup="lookup" />
        <CardDetailNs v-else-if="key === 'ns'" :lookup="lookup" />
        <CardDetailCname v-else-if="key === 'cname'" :lookup="lookup" />
        <CardDetailCaa v-else-if="key === 'caa'" :lookup="lookup" />
        <CardDetailSoa v-else-if="key === 'soa'" :lookup="lookup" />
        <CardDetailSrv v-else-if="key === 'srv'" :lookup="lookup" />
      </Card>
    </div>
  </SiteLayout>
</template>
