<script setup>
import Card from '@/Components/Card/Card.vue';
import PrivacyDetailCard from '@/Pages/DomainScore/Partials/DetailedReport/Privacy/PrivacyDetailCard.vue';
import BrandingDetailCard from '@/Pages/DomainScore/Partials/DetailedReport/Branding/BradingDetailedCard.vue';
import ImpersonationDetailCard from '@/Pages/DomainScore/Partials/DetailedReport/Impersonation/ImpersonationDetailCard.vue';
import OutlinedButton from '@/Components/Button/OutlinedButton.vue';
import { inject } from 'vue';
import CommonDetailCard from '@/Pages/DomainScore/Partials/DetailedReport/Common/CommonDetailCard.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import { Link } from '@inertiajs/vue3';

const domain = inject('domain');
const layout = inject('layout');
const download = () => window.open(route('score.export', [domain]), '_blank');
</script>

<template>
  <div class="mt-6 flex flex-col gap-6 md:mt-8 md:gap-8">
    <Card class="bg-white-opacity">
      <ImpersonationDetailCard />
    </Card>
    <Card class="bg-white-opacity">
      <PrivacyDetailCard />
    </Card>
    <Card class="bg-white-opacity">
      <BrandingDetailCard />
    </Card>
    <Card class="bg-white-opacity">
      <CommonDetailCard />
    </Card>
    <div v-if="layout === 'web'" class="flex flex-row-reverse gap-4">
      <Link :href="route('domain-analysis.show', [domain])">
        <PrimaryButton>Analyze Domain</PrimaryButton>
      </Link>
      <OutlinedButton @click="download">Download Report</OutlinedButton>
    </div>
  </div>
</template>
