<script setup>
import Card from '@/Components/Card/Card.vue';
import ExpandablePill from '@/Components/ExpandablePill.vue';
import { computed, inject, reactive } from 'vue';
import Preloader from '@/Components/Preloader.vue';
import axios from 'axios';
import ExplainTitle from '@/Pages/DomainAnalysis/Partials/ExplainTitle.vue';
import ExplainDirectivePart from '@/Pages/DomainAnalysis/Partials/ExplainDirectivePart.vue';
import Providers from '@/Pages/DomainAnalysis/Partials/Providers.vue';
import ExplainRecordValue from '@/Pages/DomainAnalysis/Partials/ExplainRecordValue.vue';
import { TLSRTP as TlsRptTags } from '@/Common/Classifiers/TagMaps.js';

const domain = inject('domain');
const result = inject('result');
const record = result.records.tlsrpt;

const state = reactive({
  result: null
});

const expand = async () => {
  if (!state.result) {
    state.result = (
      await axios.get(route('explain.show', ['tls-rpt', domain]))
    ).data;
  }
};

const directives = computed(() => {
  return (
    state.result?.directives.reduce((acc, directive) => {
      acc[directive.directive] = directive;
      return acc;
    }, {}) ?? {}
  );
});

const tlsRptTags = Array.from(TlsRptTags.map(record.tags).values()).filter(
  (t) => t?.pill != null
);
</script>

<template>
  <Card>
    <ExplainTitle :pills="tlsRptTags" title="TLS-RPT" />
    <div
      v-if="result.privacy.has_tlsrpt_record"
      class="mt-2 flex flex-col gap-4"
    >
      <ExpandablePill
        v-for="recordValue in result.records.tlsrpt.entries"
        :key="recordValue"
        :expandable="result.records.dmarc.entries.length < 2"
        @click="expand"
      >
        <ExplainRecordValue
          :status="record.status"
          :record-value="recordValue"
        />
        <template #expandable>
          <Card class="mt-2 bg-white-smoke-500 text-sm">
            <Preloader
              v-if="!state.result && !Object.keys(directives).length > 0"
              :rows="3"
            />
            <div v-else>
              <div class="flex flex-col gap-4 md:gap-3">
                <ExplainDirectivePart
                  title="Version"
                  :directive="directives.v"
                />
                <ExplainDirectivePart
                  title="Policy"
                  :directive="directives.rua"
                />
              </div>
            </div>
          </Card>
          <div class="p-4 text-sm md:px-8">
            <Providers :providers="record.tlsrpt_providers" />
          </div>
        </template>
      </ExpandablePill>
    </div>
  </Card>
</template>
