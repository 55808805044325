<script setup>
import { reactive } from 'vue';
import { router } from '@inertiajs/vue3';
import Card from '@/Components/Card/Card.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import TextInput from '@/Components/Input/TextInput.vue';

const props = defineProps({
  ip: {
    type: String,
    default: '',
    required: false
  }
});

const state = reactive({
  ipAddress: props.ip,
  isLoading: false
});

const submit = () => {
  state.isLoading = true;
  router.get(route('lookup.blacklist.show', [state.ipAddress]));
};
</script>

<template>
  <Card>
    <form @submit.prevent="submit">
      <div class="flex flex-col gap-6 md:flex-row md:items-center">
        <TextInput
          v-model.trim="state.ipAddress"
          size="large"
          label="IP Address"
          description="e.g. 127.0.0.1"
          clearable
        />
        <div class="flex">
          <PrimaryButton
            size="large"
            :is-loading="state.isLoading"
            class="mt-2"
          >
            Blacklist Lookup
          </PrimaryButton>
        </div>
      </div>
    </form>
  </Card>
</template>
