<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';
import ActionType from '@/Common/Enum/ActionType.js';
import { API_STATUS } from '@/Common/Enum/ApiStatus.js';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';

const result = inject('score');

const imageMessage = (() => {
  if (result.records.bimi.has_valid_logo) {
    return {
      message: 'Valid BIMI Image',
      actionType: ActionType.NO_ACTION
    };
  }

  if (result.records.bimi.has_logo) {
    return {
      message: 'Image found with errors',
      actionType: ActionType.ACTION_RECOMMENDED
    };
  }

  return {
    message: 'Image not found',
    actionType: ActionType.ACTION_REQUIRED
  };
})();

const certificateMessage = (() => {
  if (result.records.bimi.has_valid_certificate) {
    return {
      message: 'Valid certificate',
      actionType: ActionType.NO_ACTION
    };
  }

  if (result.records.bimi.has_certificate) {
    return {
      message: 'Certificate contains errors',
      actionType: ActionType.ACTION_RECOMMENDED
    };
  }

  return {
    message: 'No valid certificate found',
    actionType: ActionType.ACTION_REQUIRED
  };
})();
</script>

<template>
  <Card class="bg-white-opacity mt-4">
    <DetailedReportHeader
      title="BIMI"
      description="Brand Indicators for Message Identification"
      tooltip="The BIMI record works alongside DMARC, SPF and DKIM to verify an email's authenticity and help with brand visibility."
    />
    <div class="mt-5">
      <ExplainScorePart
        v-if="!result.marketing.has_bimi_record"
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        No record found
      </ExplainScorePart>

      <ExplainScorePart
        v-else-if="
          result.records.bimi.tags.some((tag) => tag.msg === 'Invalid record')
        "
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        Invalid record found -
        <LinkDomainAnalysis :domain="result.domain" fragment="bimi" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="result.records.bimi.status === API_STATUS.ERROR"
        title="DNS Record"
        :action-type="ActionType.ACTION_RECOMMENDED"
      >
        Record contain errors -
        <LinkDomainAnalysis :domain="result.domain" fragment="bimi" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else
        title="DNS Record"
        :action-type="ActionType.NO_ACTION"
      >
        Record found without errors -
        <LinkDomainAnalysis :domain="result.domain" fragment="bimi" />
      </ExplainScorePart>

      <div v-if="result.marketing.has_bimi_record">
        <ExplainScorePart title="Image" :action-type="imageMessage.actionType">
          {{ imageMessage.message }}
        </ExplainScorePart>
        <ExplainScorePart
          title="Certificate"
          :action-type="certificateMessage.actionType"
        >
          {{ certificateMessage.message }}
        </ExplainScorePart>
      </div>
    </div>
  </Card>
</template>
