<script setup>
import IconCheckCircle from '@/Components/Icons/IconCheckCircle.vue';
import IconInfo from '@/Components/Icons/IconInfo.vue';

const props = defineProps({
  title: String,
  passes: Number,
  other: Number
});

const total = props.passes + props.other;
const allPass = props.passes === total;
</script>

<template>
  <div class="mb-2 grid grid-cols-8 items-center gap-2">
    <div class="col-span-4 font-bold md:col-span-2">{{ title }}</div>
    <div class="col-span-4 md:col-span-6">
      <div class="flex items-center justify-end gap-2 md:justify-start">
        <IconCheckCircle
          v-if="total > 0 && allPass"
          :size="20"
          class="text-parakeet-500"
        />
        <IconInfo v-else :size="20" class="text-dirty-orange-500" />
        <div v-if="total === 0">None</div>
        <div v-else-if="allPass">All passed</div>
        <div v-else>{{ passes }}/{{ total }} passed</div>
      </div>
    </div>
  </div>
</template>
