import { reactive } from 'vue';

export function countryCodeEmoji(countryCode) {
  return String.fromCodePoint(
    ...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt())
  );
}

export const dateFormatterUTC = new Intl.DateTimeFormat('gregory', {
  day: 'numeric',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  timeZoneName: 'short',
  timeZone: 'UTC'
});

export class ToggleTracker {
  state;

  constructor() {
    this.state = reactive({
      _toggles: new Set()
    });
  }

  #getKey(args) {
    return Array.from(args).join(':');
  }

  toggle() {
    const key = this.#getKey(arguments);
    return this.state._toggles.has(key)
      ? this.state._toggles.delete(key)
      : this.state._toggles.add(key);
  }

  isToggled() {
    return this.state._toggles.has(this.#getKey(arguments));
  }
}

export function newInputId() {
  if (typeof crypto?.randomUUID === 'function') {
    return crypto.randomUUID();
  }

  return Math.random().toString(16);
}
