import ActionType from '@/Common/Enum/ActionType.js';
import nightcrawler, {
  NIGHTCRAWLER_CHECK_STATUS,
  onlyFailing
} from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';

export default nightcrawler({
  _root: [
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'DKIM001':
              return 'There is more than one DKIM entry in the TXT record.';
            case 'DKIM002':
              return 'The DKIM record is formatted incorrectly.';
            case 'DKIM003':
              return "The DKIM record does not contain required directives: 'p'";
            case 'DKIM004':
              return "The DKIM record does not contain recommended directives: 'v'";
            case 'DKIM005':
              return c.msg;
          }
        })
    }
  ],
  v: [
    {
      default: true,
      required: true,
      actionType: ActionType.NO_ACTION_WITH_DEFAULT,
      message: 'DKIM1'
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ['First directive should be v=DKIM1.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  p: [
    {
      default: true,
      required: true,
      actionType: ActionType.ACTION_REQUIRED,
      value: '',
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      message:
        'Not specified (Should be a string of characters representing the public key)'
    },
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'DKIMP01':
              return 'Public key is invalid.';
            case 'DKIMP02':
              return 'Key length is not secure.';
            case 'DKIMP03':
              return 'Key may not fit in DNS UDP response.';
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  k: [
    {
      default: true,
      required: false,
      actionType: ActionType.NO_ACTION_WITH_DEFAULT,
      message: 'rsa'
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.ACTION_REQUIRED,
      errorMessages: ['The DKIM record does not contain a standard key type.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  s: [
    {
      default: true,
      required: false,
      actionType: ActionType.NO_ACTION_WITH_DEFAULT,
      message: '*: All Service Types'
    },
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'DKIMS01':
              return 'Service Types should be a colon-delimited list.';
            case 'DKIMS02':
              return c.msg;
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  h: [
    {
      default: true,
      required: false,
      actionType: ActionType.NO_ACTION_WITH_DEFAULT,
      message: 'all: All Algorithms'
    },
    {
      status: [NIGHTCRAWLER_CHECK_STATUS.WARN, NIGHTCRAWLER_CHECK_STATUS.ERROR],
      actionType: ({ status }) =>
        ({
          [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
          [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
        })[status],
      errorMessages: ({ checks }) =>
        onlyFailing(checks).map((c) => {
          switch (c.msg_code) {
            case 'DKIMH01':
              return 'Acceptable hash algorithms should be colon-delimited list.';
            case 'DKIMH02':
              return c.msg;
          }
        })
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  t: (() => {
    const msgFunc = ({ value }) => {
      return value
        .split(':')
        .map((v) => {
          switch (v) {
            case 'y':
              return 'y: Domain is testing DKIM, do not treat differently to unsigned messages.';
            case 's':
              return `s: Domain in "i=" tag must match the value of the "d=" tag exactly.`;
          }
        })
        .join('\n');
    };
    return [
      {
        default: true,
        required: false,
        actionType: ActionType.ACTION_OPTIONAL,
        message: '(Optional, default empty)'
      },
      {
        status: [
          NIGHTCRAWLER_CHECK_STATUS.WARN,
          NIGHTCRAWLER_CHECK_STATUS.ERROR
        ],
        actionType: ({ status }) =>
          ({
            [NIGHTCRAWLER_CHECK_STATUS.WARN]: ActionType.ACTION_RECOMMENDED,
            [NIGHTCRAWLER_CHECK_STATUS.ERROR]: ActionType.ACTION_REQUIRED
          })[status],
        errorMessages: ({ checks }) =>
          onlyFailing(checks).map((c) => {
            switch (c.msg_code) {
              case 'DKIMT01':
                return 'Flags should be a colon-delimited list.';
              case 'DKIMT02':
                return c.msg;
            }
          })
      },
      {
        status: NIGHTCRAWLER_CHECK_STATUS.OK,
        value: ['y', 'y:s', 's:y'],
        actionType: ActionType.NO_ACTION,
        message: msgFunc
      },
      {
        status: NIGHTCRAWLER_CHECK_STATUS.OK,
        actionType: ActionType.NO_ACTION,
        message: msgFunc
      }
    ];
  })(),
  n: [
    {
      default: true,
      required: false,
      actionType: ActionType.ACTION_OPTIONAL,
      message:
        '(Optional, default empty. Notes that may be of interest to a human.)'
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ],
  g: [
    {
      status: NIGHTCRAWLER_CHECK_STATUS.ERROR,
      actionType: ActionType.NO_ACTION,
      errorMessages: ['Granularity is empty.']
    },
    {
      status: NIGHTCRAWLER_CHECK_STATUS.OK,
      actionType: ActionType.NO_ACTION
    }
  ]
});
