<script setup>
import { reactive } from 'vue';
import Alert from '@/Components/Alert.vue';

const props = defineProps({
  rows: {
    type: Number,
    default: 6,
    required: false
  },
  timeout: {
    type: Number,
    default: 5000,
    required: false
  }
});
const errors = reactive([]);

setTimeout(() => {
  errors.push('Please refresh the page and try again.');
}, props.timeout);
</script>

<template>
  <Alert
    v-if="errors.length"
    variant="danger"
    title="An error has occurred loading this resource"
    class="mb-3"
  >
    <div v-for="(error, index) in errors" :key="index">
      <p class="text-red-950">{{ error }}</p>
    </div>
  </Alert>
  <div class="flex flex-col gap-2">
    <div v-for="index in rows" :key="index" class="flex gap-2">
      <div class="h-4 w-2/6 animate-pulse rounded-lg bg-gray-200" />
      <div class="h-4 w-2/6 animate-pulse rounded-lg bg-gray-200" />
      <div class="h-4 w-2/6 animate-pulse rounded-lg bg-gray-200" />
    </div>
  </div>
</template>
