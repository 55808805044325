import { dateFormatterUTC } from '@/Common/utils.js';

export function formatProperty(property, value) {
  // noinspection FallThroughInSwitchStatementJS
  switch (property) {
    case 'timestamp':
      try {
        return dateFormatterUTC.format(Date.parse(value));
      } catch {
        /*nocatch*/
      }
    // eslint-disable-next-line no-fallthrough
    default:
      return value;
  }
}
