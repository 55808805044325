<script setup>
import LookupSelect from '@/Pages/Lookup/Partials/LookupSelect.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import { reactive } from 'vue';
import HostNameInput from '@/Components/Input/HostNameInput.vue';

const props = defineProps({
  lookupType: {
    type: String,
    default: 'DNS'
  },
  domain: {
    type: String,
    default: ''
  }
});

const state = reactive({
  domain: props.domain,
  isLoading: false
});

const emits = defineEmits(['submitted']);

const submit = () => {
  state.isLoading = true;
  emits('submitted', state.domain);
};
</script>
<template>
  <form @submit.prevent="submit">
    <div class="flex flex-col gap-4 md:flex-row">
      <div class="md:w-3/12">
        <LookupSelect :selected="lookupType" />
      </div>
      <HostNameInput
        v-model.trim="state.domain"
        class="flex-1"
        size="large"
        label="Domain Name"
        description="e.g. example.com"
        clearable
        autofocus
        required
      />
      <div class="flex md:items-center md:justify-center">
        <PrimaryButton size="large" :is-loading="state.isLoading" class="mt-2">
          Lookup
        </PrimaryButton>
      </div>
    </div>
  </form>
</template>
