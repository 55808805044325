<script setup>
import Card from '@/Components/Card/Card.vue';

const props = defineProps({
  variant: {
    type: String,
    validator: (value) => ['success', 'warning', 'danger'].includes(value)
  }
});

const classes = {
  success: 'flare before:bg-parakeet-500',
  warning: 'flare before:bg-dirty-orange-500',
  danger: 'flare before:bg-rio-red-500 popop'
}[props.variant];
</script>

<template>
  <Card dark-mode :class="classes">
    <slot />
  </Card>
</template>

<style scoped>
.flare {
  position: relative;
  overflow: hidden;
}

.flare::before {
  width: 637px;
  height: 637px;
  position: absolute;
  left: 0;
  top: -520px;
  border-radius: 301px;
  opacity: 0.6;
  filter: blur(100px);
  content: '';
  z-index: 0;
}
</style>
