const codes = {
  TLSRPT001: 'There is more than one TLS-RPT entry in the TXT record.',
  TLSRPT002: 'The TLS-RPT record is formatted incorrectly.',
  TLSRPT003: "The TLS-RPT record does not contain required directives: 'v'.",
  TLSRPTV01: 'First directive should be v=TLSRPTv1',
  TLSRPTRUA00:
    'Reporting Addresses should be a comma separated list of addresses.',
  TLSRPTRUA01: 'Delivery method is not mailto or https.',
  TLSRPTRUA02: 'Email addresses are invalid.',
  TLSRPTRUA03: 'Report domains contain invalid MX records.',
  TLSRPTRUA04: 'URL is invalid.',
  TLSRPTRUA05: 'URL hosts missing valid A records.',

  MTASTS001: 'There is more than one MTA-STS entry in the TXT record.',
  MTASTS002: 'The MTA-STS record is formatted incorrectly.',
  MTASTS003:
    "The MTA-STS record does not contain required directives: 'v','id'.",
  MTASTSV01: 'First should be v=STSv1.',
  MTASTSID01: 'ID has no value.',
  MTASTSHTTPS01: 'Policy should be hosted on an HTTPS service.',
  MTASTSHTTPS02: 'Policy content-type should be text/plain.',
  MTASTSHTTPS03: 'Policy is formatted incorrectly.',
  MTASTSHTTPS04:
    "Policy does not contain required fields: 'version','mode','max_age'.",
  MTASTSVERSION01: 'Version should be STSv1.',
  MTASTSMODE01: "Mode should be 'enforce', 'testing' or 'none'.",
  MTASTSMAXAGE01: 'Max-age should be an integer.',
  MTASTSMAXAGE02: 'Max-age should be longer than a day.',
  MTASTSMX01: 'Invalid domain (or invalid wildcard domain)',

  BIMI001: 'There is more than one BIMI entry in the TXT record.',
  BIMI002: 'The BIMI record is formatted incorrectly.',
  BIMI003: "The BIMI record does not contain required directives: 'v','l'.",
  BIMIV01: 'First directive should be v=BIMI1.',
  BIMIL01: "SVG logo should have a '.svg' extension.",
  BIMIL02: 'SVG logo should be hosted on an HTTPS service.',
  BIMIL03: "SVG logo should be content-type of 'image/svg+xml''",
  BIMIL04: 'The SVG logo should be under 32KB.',
  BIMIL05: 'The SVG logo failed to parse.',
  BIMIL06: "The SVG logo baseProfile should be 'tiny-ps'.",
  BIMIL07: "The SVG logo version should be '1.2'.",
  BIMIA01: "Certificate should have '.pem' extension.",
  BIMIA02: 'Certificate should be hosted on an HTTPS service.',
  BIMIA03: 'Certificate is invalid.',
  BIMIA04: 'Certificate has expired.'
};

export default function translateNightCrawlerCode(check) {
  if (codes.hasOwnProperty(check.msg_code)) {
    return codes[check.msg_code];
  }
  return check.msg;
}
