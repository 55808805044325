<script setup>
import { getActionTypeFromStatus } from '@/Common/Enum/ActionType.js';
import StatusIcon from '@/Pages/Common/StatusIcon.vue';
import { computed } from 'vue';

const { status } = defineProps({
  recordValue: String,
  status: String,
  expanded: Boolean
});

const actionType = computed(() => {
  return getActionTypeFromStatus(status);
});
</script>

<template>
  <div class="flex flex-1 justify-between">
    <div
      :class="{
        truncate: !expanded,
        'whitespace-normal text-pretty break-all': expanded
      }"
      class="font-mono"
    >
      {{ recordValue }}
    </div>
    <div class="pl-4">
      <StatusIcon v-tooltip="actionType.tooltip" :action-type="actionType" />
    </div>
  </div>
</template>
