<script setup>
import { getActionTypeFromScore } from '@/Common/Enum/ActionType.js';
import SquareScore from '@/Components/Chart/SquareScore.vue';
import { computed } from 'vue';

const props = defineProps({
  score: Number
});

const variant = getActionTypeFromScore(props.score).color;

const scoreOutOfFive = computed(() => Math.floor(props.score / 20));
</script>

<template>
  <SquareScore :score="scoreOutOfFive" :variant="variant" />
</template>
