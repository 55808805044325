import * as Sentry from '@sentry/vue';
export const NIGHTCRAWLER_CHECK_STATUS = {
  OK: 'OK',
  INFO: 'Info',
  WARN: 'Warn',
  ERROR: 'Error',
  FATAL: 'Fatal',
  DISABLED: 'Disabled'
};

export class NightcrawlerCheckClassifier {
  constructor(taxonomy) {
    this.taxononmy = taxonomy;
  }

  /**
   * @return {NightcrawlerMessage|null}
   * */
  get(directiveName, status, checks, value) {
    const tax = this.taxononmy[directiveName] ?? [];

    const classification =
      (status &&
        tax
          .filter((i) => this.#evaluateStatusMatch(i.status, status))
          .find((i) => this.#evaluateValueMatch(i, value))) ??
      tax.find((i) => i?.default);

    if (!classification) {
      Sentry.captureMessage('Classification is not defined for directive', {
        level: 'warning',
        tags: {
          directiveName,
          status,
          checks,
          value
        }
      });
    }

    return (
      classification &&
      new NightcrawlerMessage(
        directiveName,
        classification.required ? 'error' : 'info',
        this.#evaluateActionType(classification, { status, checks, value }),
        this.#evaluateMessageField(
          classification,
          'message',
          { checks, value },
          value
        ),
        this.#evaluateMessageField(
          classification,
          'errorMessages',
          { checks, value },
          null
        ),
        Boolean(classification.default)
      )
    );
  }

  #evaluateStatusMatch(statuses, value) {
    if (Array.isArray(statuses)) {
      return statuses.includes(value);
    }

    return value === statuses;
  }

  #evaluateActionType(classification, { status, checks, value }) {
    if (typeof classification.actionType === 'function') {
      return classification.actionType({ status, checks, value });
    }

    return classification.actionType;
  }

  #evaluateValueMatch(classification, value) {
    if (!classification.hasOwnProperty('value')) {
      return true;
    }

    if (typeof classification.value === 'function') {
      return classification.value(value);
    }

    if (Array.isArray(classification.value)) {
      return classification.value.includes(value);
    }

    return classification.value === value;
  }

  #evaluateMessageField(classification, field, { checks, value }, _default) {
    if (!classification.hasOwnProperty(field)) {
      return _default;
    }

    if (typeof classification[field] === 'function') {
      return classification[field]({ checks, value });
    }

    return classification[field] ?? _default;
  }
}

class NightcrawlerMessage {
  directive;
  type;
  actionType;
  message;
  isDefault;
  /**
   * @param {string[]} errorMessages
   */
  errorMessages;
  constructor(directive, type, actionType, message, errorMessages, isDefault) {
    this.directive = directive;
    this.type = type;
    this.actionType = actionType;
    this.message = message;
    this.errorMessages = errorMessages;
    this.isDefault = isDefault;
  }
}

export default function nightcrawler(taxonomy) {
  return new NightcrawlerCheckClassifier(taxonomy);
}

export function onlyFailing(checks) {
  return checks.filter(
    (c) =>
      c.status !== NIGHTCRAWLER_CHECK_STATUS.OK &&
      c.status !== NIGHTCRAWLER_CHECK_STATUS.INFO
  );
}
