<script setup>
import { computed, provide, ref } from 'vue';
import DKIMDirectiveMessaging from '@/Common/Classifiers/DKIMDirectiveMessaging.js';
import { NIGHTCRAWLER_CHECK_STATUS } from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';
import ActionType from '@/Common/Enum/ActionType.js';
import Alert from '@/Components/Alert.vue';
import DkimExplainDirective from '@/Pages/DomainAnalysis/Partials/DkimExplainDirective.vue';

const props = defineProps({
  record: Object
});

const directives = props.record.directives.reduce((acc, directive) => {
  acc[directive.directive] = directive;
  return acc;
}, {});

const recError = computed(() => {
  if (
    [NIGHTCRAWLER_CHECK_STATUS.ERROR, NIGHTCRAWLER_CHECK_STATUS.FATAL].includes(
      props.record.status
    )
  ) {
    return DKIMDirectiveMessaging.get(
      '_root',
      NIGHTCRAWLER_CHECK_STATUS.ERROR,
      props.record.checks,
      null
    );
  }

  return null;
});

provide('directives', ref(directives));
</script>

<template>
  <div v-if="recError">
    <Alert
      :variant="ActionType.ACTION_REQUIRED.color"
      title="Record Contains Errors"
    >
      <div v-for="(error, index) in recError.errorMessages" :key="index">
        {{ error }}
      </div>
    </Alert>
  </div>
  <div v-else class="flex flex-col gap-4 md:gap-3">
    <DkimExplainDirective title="Version" :directive="directives.v" />
    <DkimExplainDirective title="Key Type" :directive="directives.k" />
    <DkimExplainDirective title="Public Key" :directive="directives.p" />
    <DkimExplainDirective title="Flags" :directive="directives.t" />
    <DkimExplainDirective title="Service Type" :directive="directives.k" />
    <DkimExplainDirective
      title="Acceptable Hash Algorithms"
      :directive="directives.h"
    />
    <DkimExplainDirective title="Notes" :directive="directives.n" />
  </div>
</template>
