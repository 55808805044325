<script setup>
import Card from '@/Components/Card/Card.vue';
import ExpandablePill from '@/Components/ExpandablePill.vue';
import { computed, inject, provide, reactive } from 'vue';
import Preloader from '@/Components/Preloader.vue';
import DmarcMessaging from '@/Common/Classifiers/DmarcDirectiveMessaging.js';
import { DMARC as DmarcTags } from '@/Common/Classifiers/TagMaps.js';
import ActionType from '@/Common/Enum/ActionType.js';
import { NIGHTCRAWLER_CHECK_STATUS } from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';
import Alert from '@/Components/Alert.vue';
import ExplainTitle from '@/Pages/DomainAnalysis/Partials/ExplainTitle.vue';
import Providers from '@/Pages/DomainAnalysis/Partials/Providers.vue';
import ExplainDirectiveCard from '@/Pages/DomainAnalysis/Partials/ExplainDirectiveCard.vue';
import ExplainRecordValue from '@/Pages/DomainAnalysis/Partials/ExplainRecordValue.vue';
import ExplainDirectivePart from '@/Pages/DomainAnalysis/Partials/ExplainDirectivePart.vue';
import RedirectPresent from '@/Pages/DomainAnalysis/Partials/RedirectPresent.vue';
import DmarcExplainDirective from '@/Pages/DomainAnalysis/Partials/DmarcExplainDirective.vue';
const domain = inject('domain');
const result = inject('result');
const record = result.records.dmarc;

const state = reactive({
  result: null
});

const expand = async () => {
  if (!state.result) {
    state.result = (
      await axios.get(route('explain.show', ['dmarc', domain]))
    ).data;
  }
};

const directives = computed(() => {
  return (
    state.result?.directives.reduce((acc, directive) => {
      acc[directive.directive] = directive;
      return acc;
    }, {}) ?? {}
  );
});

const dmarcTags = Array.from(DmarcTags.map(record.tags).values()).filter(
  (t) => t?.pill != null
);

const rootError = computed(() => {
  if (
    state.result &&
    [NIGHTCRAWLER_CHECK_STATUS.ERROR, NIGHTCRAWLER_CHECK_STATUS.FATAL].includes(
      state.result.status
    )
  ) {
    return DmarcMessaging.get(
      '_root',
      NIGHTCRAWLER_CHECK_STATUS.ERROR,
      state.result.checks,
      null
    );
  }

  return null;
});

provide('directives', directives);
</script>

<template>
  <Card>
    <ExplainTitle :pills="dmarcTags" title="DMARC">
      <template #after>
        <RedirectPresent v-if="record.cnames?.length > 0" />
      </template>
    </ExplainTitle>
    <div
      v-if="result.impersonation.has_dmarc_record"
      class="mt-2 flex flex-col gap-4"
    >
      <ExpandablePill
        v-for="recordValue in result.records.dmarc.entries"
        :key="recordValue"
        :expandable="result.records.dmarc.entries.length < 2"
        @click="expand"
      >
        <template #default="{ expanded }">
          <ExplainRecordValue
            :status="record.status"
            :record-value="recordValue"
            :expanded="expanded"
          />
        </template>
        <template #expandable>
          <div v-if="rootError" class="mt-6 px-5">
            <Alert
              :variant="ActionType.ACTION_REQUIRED.color"
              title="Record Contains Errors"
            >
              <div
                v-for="(error, index) in rootError.errorMessages"
                :key="index"
              >
                {{ error }}
              </div>
            </Alert>
          </div>
          <ExplainDirectiveCard
            v-else-if="!state.result && !Object.keys(directives).length > 0"
          >
            <Preloader :rows="14" />
          </ExplainDirectiveCard>
          <ExplainDirectiveCard v-else>
            <DmarcExplainDirective title="Version" :directive="directives.v" />
            <DmarcExplainDirective title="Policy" :directive="directives.p" />
            <DmarcExplainDirective
              title="Sub-domain Policy"
              :directive="directives.sp"
            />
            <DmarcExplainDirective
              title="Sampling Rate Percentage"
              :directive="directives.pct"
            />
            <DmarcExplainDirective
              title="DKIM Alignment Mode"
              :directive="directives.adkim"
            />
            <DmarcExplainDirective
              title="SPF Alignment Mode"
              :directive="directives.aspf"
            />
            <DmarcExplainDirective
              title="Reporting URI(s) for Aggregate Data"
              :directive="directives.rua"
            />
            <DmarcExplainDirective
              title="Aggregate Reporting Interval"
              :directive="directives.ri"
            />
            <DmarcExplainDirective
              title="Reporting URI(s) for Failures Data"
              :directive="directives.ruf"
            />
            <DmarcExplainDirective
              title="Failure Reporting Format(s)"
              :directive="directives.rf"
            />
            <DmarcExplainDirective
              title="Failure Reporting Options"
              :directive="directives.fo"
            />
          </ExplainDirectiveCard>
          <div class="flex flex-col gap-2 p-4 text-sm md:px-8">
            <Providers :providers="record.dmarc_providers" />
            <ExplainDirectivePart
              v-if="record.cnames?.length > 0"
              title="Redirect"
              :directive="{
                checks: [],
                value: record.cnames.map((cname) => cname.cname).join(', ')
              }"
            >
              <template #detail>
                -
                <a
                  class="text-sm text-blue-600 underline hover:text-blue-500"
                  :href="route('lookup.show', ['dmarc', domain])"
                >
                  Lookup DMARC
                </a>
              </template>
            </ExplainDirectivePart>
          </div>
        </template>
      </ExpandablePill>
    </div>
  </Card>
</template>
