<template>
  <div class="flex flex-col gap-1 md:flex-row md:gap-8">
    <div class="flex justify-between font-bold md:w-1/3">
      <slot />
    </div>
    <div class="flex flex-1 justify-between">
      <slot name="detail" />
      <slot name="icon" />
    </div>
  </div>
</template>
