<script setup>
import Card from '@/Components/Card/Card.vue';
import CardLabel from '@/Components/Card/CardLabel.vue';
import { dateFormatterUTC, ToggleTracker } from '@/Common/utils.js';
import PropertyListItem from '@/Pages/HeaderAnalysis/Partials/PropertyListItem.vue';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';
import RdapDetail from '@/Pages/HeaderAnalysis/Partials/RdapDetail.vue';

defineProps({
  summary: Object,
  sender: Object
});

const toggler = new ToggleTracker();
</script>

<template>
  <div>
    <CardLabel label="Summary" />
    <Card>
      <div class="flex flex-col gap-2">
        <div
          class="flex cursor-pointer justify-between"
          @click="toggler.toggle('from')"
        >
          <PropertyListItem
            title="From"
            :value="summary.from"
            empty-value="–"
            class="w-11/12"
          />
          <IconExpandCircleDown
            class="text-navy-200 transition-transform duration-300"
            :class="{ '-rotate-180': toggler.isToggled('from') }"
          />
        </div>
        <Card
          v-if="toggler.isToggled('from')"
          class="!bg-white-smoke-500"
          :pad-bottom="true"
        >
          <RdapDetail :rdap="sender.from_rdap" />
        </Card>
        <PropertyListItem
          title="To"
          :value="summary.to"
          empty-value="–"
          class="w-11/12"
        />
        <PropertyListItem
          title="Cc"
          :value="summary.cc"
          empty-value="–"
          class="w-11/12"
        />
        <div
          class="flex cursor-pointer justify-between"
          @click="toggler.toggle('return_path')"
        >
          <PropertyListItem
            title="Return Path"
            :value="summary.return_path"
            empty-value="–"
            class="w-11/12"
          />
          <IconExpandCircleDown
            class="text-navy-200 transition-transform duration-300"
            :class="{ '-rotate-180': toggler.isToggled('return_path') }"
          />
        </div>
        <Card
          v-if="toggler.isToggled('return_path')"
          class="!bg-white-smoke-500"
          :pad-bottom="true"
        >
          <RdapDetail :rdap="sender.return_path_rdap" />
        </Card>
        <PropertyListItem
          title="Date"
          :value="
            summary.date && dateFormatterUTC.format(Date.parse(summary.date))
          "
          empty-value="–"
          class="w-11/12"
        />
        <PropertyListItem
          title="Subject"
          :value="summary.subject"
          class="w-11/12"
        />
        <PropertyListItem
          title="Message ID"
          :value="summary.message_id"
          empty-value="–"
          class="w-11/12"
        />
      </div>
    </Card>
  </div>
</template>
