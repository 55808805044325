<script setup>
const props = defineProps({
  geo: Object,
  boldFieldNames: {
    type: Boolean,
    default: false
  }
});

const fieldClasses = {
  'w-1/3': true,
  'shrink-0': true,
  'font-bold': props.boldFieldNames
};
</script>

<template>
  <div>
    <div class="font-bold">GEO</div>
    <div class="flex">
      <div :class="fieldClasses">City</div>
      <div v-if="geo?.city">{{ geo.city }}</div>
      <div v-else>–</div>
    </div>
    <div class="flex">
      <div :class="fieldClasses">Country</div>
      <div v-if="geo?.country">{{ geo.country }}</div>
      <div v-else>–</div>
    </div>
    <div class="flex">
      <div :class="fieldClasses">Continent</div>
      <div v-if="geo?.continent">{{ geo.continent }}</div>
      <div v-else>–</div>
    </div>
    <div class="flex">
      <div :class="fieldClasses">Time Zone</div>
      <div v-if="geo?.time_zone">{{ geo.time_zone }}</div>
      <div v-else>–</div>
    </div>
    <div class="flex">
      <div :class="fieldClasses">Coordinates</div>
      <div v-if="geo?.latitude && geo.longitude">
        {{ geo.latitude }}, {{ geo.longitude }}
      </div>
      <div v-else>–</div>
    </div>
    <div class="flex">
      <div :class="fieldClasses">ASN</div>
      <div v-if="geo?.asn">{{ geo.asn }}</div>
      <div v-else>–</div>
    </div>
    <div class="flex">
      <div :class="fieldClasses">ASN Name</div>
      <div v-if="geo?.asn_name">{{ geo.asn_name }}</div>
      <div v-else>–</div>
    </div>
    <div class="flex">
      <div :class="fieldClasses">Network</div>
      <div v-if="geo?.network">{{ geo.network }}</div>
      <div v-else>–</div>
    </div>
  </div>
</template>
