<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import TextInput from '@/Components/Input/TextInput.vue';
import LookupSelect from '@/Pages/Lookup/Partials/LookupSelect.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import { reactive } from 'vue';
import Card from '@/Components/Card/Card.vue';
import CardLabel from '@/Components/Card/CardLabel.vue';
import KnownSelectors from '@/Pages/Explain/Partials/KnownSelectors.vue';
import { router } from '@inertiajs/vue3';
import Alert from '@/Components/Alert.vue';
import CardDetailNs from '@/Pages/Lookup/Partials/Dns/CardDetailNs.vue';

const props = defineProps({
  domain: {
    type: String,
    default: ''
  },
  selector: {
    type: String,
    default: ''
  },
  result: {
    type: Object,
    default: null
  },
  ns: {
    type: Object,
    default: null
  }
});

const state = reactive({
  domain: props.domain,
  selector: props.selector,
  isLoading: false
});

const submit = () => {
  state.isLoading = true;
  router.visit(route('explain.dkim', [state.domain, state.selector]));
};
</script>

<template>
  <SiteLayout title="DKIM lookup">
    <Card>
      <form @submit.prevent="submit">
        <div class="flex flex-col gap-6 md:flex-row">
          <div class="md:w-3/12">
            <LookupSelect selected="DKIM" />
          </div>
          <TextInput
            v-model.trim="state.selector"
            class="flex-1"
            size="large"
            label="Selector"
            description="e.g. selector1"
            clearable
          />
          <TextInput
            v-model.trim="state.domain"
            class="flex-1"
            size="large"
            label="Domain Name"
            description="e.g. example.com"
            clearable
            autofocus
            required
          />
          <div class="flex md:items-center md:justify-center">
            <PrimaryButton
              size="large"
              :is-loading="state.isLoading"
              class="mt-2"
            >
              Lookup
            </PrimaryButton>
          </div>
        </div>
      </form>
    </Card>

    <Card v-if="result && result.hasOwnProperty('detail')">
      <Alert variant="danger" title="Record not found">
        {{ result.detail }}
      </Alert>
    </Card>

    <div v-else-if="result && !selector">
      <CardLabel label="Known Selectors" />
      <Card>
        <KnownSelectors :domain="domain" :selectors="result.records" />
      </Card>
    </div>

    <div v-else-if="result && selector">
      <CardLabel
        :label="`DKIM Selector &ldquo;${selector}&rdquo; for ${domain}`"
      />
      <Card>
        <Alert variant="success" class="mb-6">
          <div class="text-lg">DKIM Record Found</div>
        </Alert>
        <KnownSelectors :domain="domain" :selectors="[result]" expanded />
      </Card>
    </div>

    <div>
      <CardLabel label="Name Servers" />
      <Card>
        <CardDetailNs :lookup="ns" />
      </Card>
    </div>
  </SiteLayout>
</template>
