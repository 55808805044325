<script setup>
import Card from '@/Components/Card/Card.vue';
import SiteLayout from '@/Layouts/Layout.vue';
import Tab from '@/Components/Tab/Tab.vue';
import TabItem from '@/Components/Tab/TabItem.vue';
import { reactive } from 'vue';
import IpAddressForm from '@/Pages/CidrCalculator/Partials/IpAddressForm.vue';
import CidrToIpForm from '@/Pages/CidrCalculator/Partials/CidrToIpForm.vue';
import IpToCidrForm from '@/Pages/CidrCalculator/Partials/IpToCidrForm.vue';

const state = reactive({
  tab: 1
});
</script>

<template>
  <SiteLayout title="CIDR Calculator">
    <Card>
      <div
        class="flex flex-1 flex-col flex-wrap gap-4 md:flex-row md:items-start"
      >
        <div>
          <div class="mb-1">Check</div>
          <Tab contained>
            <TabItem
              :active="state.tab === 1"
              title="IP Address"
              @click.prevent="state.tab = 1"
            />
            <TabItem
              :active="state.tab === 2"
              title="CIDR to IP Range"
              @click.prevent="state.tab = 2"
            />
            <TabItem
              :active="state.tab === 3"
              title="IP Range to CIDR"
              @click.prevent="state.tab = 3"
            />
          </Tab>
        </div>
        <IpAddressForm v-if="state.tab === 1" />
        <CidrToIpForm v-if="state.tab === 2" />
        <IpToCidrForm v-if="state.tab === 3" />
      </div>
    </Card>
    <section id="results" />
  </SiteLayout>
</template>
