<script setup>
import { nextTick, reactive, ref, watch } from 'vue';

const props = defineProps({
  collapsed: {
    type: Boolean,
    default: false
  },
  minHeight: {
    type: Number,
    default: 0
  }
});
const inner = ref(null);
const maxHeight = ref('auto');
const reactiveProps = reactive(props);

watch(reactiveProps, () => {
  nextTick(() => {
    const height = reactiveProps.collapsed ? 0 : inner.value.scrollHeight;
    maxHeight.value = `${Math.max(props.minHeight, height)}px`;
  });
});
</script>

<template>
  <div
    class="wrapper"
    :class="{
      collapsed: collapsed,
      hidden: collapsed && minHeight === 0
    }"
  >
    <div class="w-full">
      <div
        ref="inner"
        class="inner overflow-hidden duration-300"
        :style="{ maxHeight }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>
