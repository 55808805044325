<script setup>
import IconHelp from '@/Components/Icons/IconHelp.vue';
import { defineProps, inject } from 'vue';

defineProps({
  text: String,
  size: {
    type: Number,
    default: 24
  }
});

const layout = inject('layout');
</script>

<template>
  <div
    v-if="layout === 'web'"
    v-tooltip="text"
    class="cursor-pointer font-normal"
  >
    <IconHelp :size="size" />
  </div>
</template>
