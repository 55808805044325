<script setup>
import TextInput from '@/Components/Input/TextInput.vue';

const [model] = defineModel({
  type: String,
  set(value) {
    // Only strip the hostname 'www' if the protocol is present (indicates a copy/paste)
    const stripUrl = (input) =>
      input.replace(/^\w+:\/\/(www\.)?/i, '').split('/')[0];
    const stripEmail = (input) => input.replace(/^.*@/i, '');

    // If we add more, we should use a pipes composer
    return stripUrl(stripEmail(value));
  }
});
</script>

<template>
  <TextInput
    inputmode="url"
    autocomplete="off"
    v-bind="$attrs"
    v-model.trim="model"
  />
</template>
