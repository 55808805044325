<script setup>
import Card from '@/Components/Card/Card.vue';
import { inject } from 'vue';
import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import { API_STATUS } from '@/Common/Enum/ApiStatus.js';
import LinkDomainAnalysis from '@/Pages/DomainScore/Partials/DetailedReport/LinkDomainAnalysis.vue';
import DetailedReportHeader from '@/Pages/DomainScore/Partials/DetailedReport/DetailedReportHeader.vue';
import ActionType from '@/Common/Enum/ActionType.js';
import ExplainScorePart from '@/Pages/DomainScore/Partials/DetailedReport/ExplainScorePart.vue';

const result = inject('score');

const tags =
  result.records.dmarc?.tags?.reduce((acc, tag) => {
    acc[tag.msg] = tag;
    return acc;
  }, {}) ?? {};
</script>

<template>
  <Card class="bg-white-opacity">
    <DetailedReportHeader
      title="DMARC"
      description="Domain-based Message Authentication, Reporting and Conformance"
      tooltip="The DMARC record defines your domain’s DMARC policy, it specifies what receiving email servers should do with emails received from your domain."
    />
    <div class="mt-6 flex flex-col">
      <ExplainScorePart
        v-if="!result.impersonation.has_dmarc_record"
        title="DNS Record"
        :action-type="ACTION_TYPES.ACTION_REQUIRED"
      >
        Record not found -
        <LinkDomainAnalysis :domain="result.domain" fragment="dmarc" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="tags.hasOwnProperty('Too many records')"
        title="DNS Record"
        :action-type="ACTION_TYPES.ACTION_REQUIRED"
      >
        Multiple DMARC records found -
        <LinkDomainAnalysis :domain="result.domain" fragment="dmarc" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="
          [API_STATUS.FAIL, API_STATUS.FATAL].includes(
            result.records.dmarc.status
          )
        "
        title="DNS Record"
        :action-type="ActionType.ACTION_REQUIRED"
      >
        Invalid record found -
        <LinkDomainAnalysis :domain="result.domain" fragment="dmarc" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="result.records?.dmarc?.status === API_STATUS.ERROR"
        title="DNS Record"
        :action-type="ACTION_TYPES.ACTION_RECOMMENDED"
      >
        Record found with errors -
        <LinkDomainAnalysis :domain="result.domain" fragment="dmarc" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else-if="result.records?.dmarc?.status === API_STATUS.INFO"
        title="DNS Record"
        :action-type="ACTION_TYPES.INFO"
      >
        Record found with warnings -
        <LinkDomainAnalysis :domain="result.domain" fragment="dmarc" />
      </ExplainScorePart>
      <ExplainScorePart
        v-else
        title="DNS Record"
        :action-type="ACTION_TYPES.NO_ACTION"
      >
        Record found without errors -
        <LinkDomainAnalysis :domain="result.domain" fragment="dmarc" />
      </ExplainScorePart>

      <div
        v-if="result.records?.dmarc?.status !== API_STATUS.FATAL"
        class="flex flex-col"
      >
        <ExplainScorePart
          v-if="tags.hasOwnProperty('p=none')"
          title="Policy"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Policy set to 'none'; no email handling specified.
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="tags.hasOwnProperty('p=quarantine')"
          title="Policy"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Policy set to 'quarantine'; accept all email as possibly valid. Emails
          are considered suspicious.
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="tags.hasOwnProperty('p=reject')"
          title="Policy"
          :action-type="ACTION_TYPES.NO_ACTION"
        >
          Policy set to 'reject'
        </ExplainScorePart>

        <ExplainScorePart
          v-if="tags.hasOwnProperty('sp=none')"
          title="Subdomain Policy"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Subdomain policy set to 'none'; no email handling specified.
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="tags.hasOwnProperty('sp=quarantine')"
          title="Subdomain Policy"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Subdomain policy set to 'quarantine'; accept all email as possibly
          valid. Emails are considered suspicious.
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="tags.hasOwnProperty('sp=reject')"
          title="Subdomain Policy"
          :action-type="ACTION_TYPES.NO_ACTION"
        >
          Subdomain Policy set to 'reject'
        </ExplainScorePart>

        <ExplainScorePart
          v-if="tags.hasOwnProperty('pct=0')"
          title="Percentage"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Policy is applied to 0% of emails
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="tags.hasOwnProperty('pct<100')"
          title="Percentage"
          :action-type="ACTION_TYPES.ACTION_REQUIRED"
        >
          Policy is only applied to
          {{ result.records.dmarc.policy_percentage }}% of emails
        </ExplainScorePart>
        <ExplainScorePart
          v-else
          title="Percentage"
          :action-type="ACTION_TYPES.NO_ACTION"
        >
          Policy is applied to 100% of emails
        </ExplainScorePart>

        <ExplainScorePart
          v-if="tags.hasOwnProperty('rua=[]')"
          title="Aggregate Reporting"
          :action-type="ACTION_TYPES.INFO"
        >
          Reporting Address not specified, it's important to monitor your
          reports and ensure everything is working correctly.
        </ExplainScorePart>
        <ExplainScorePart
          v-if="
            result.records.dmarc.aggregate_reporting_addresses.some((email) =>
              email.toLowerCase().includes(result.domain)
            )
          "
          title="Aggregate Reporting"
          :action-type="ACTION_TYPES.ACTION_RECOMMENDED"
        >
          Reporting Address set to '{{
            result.records.dmarc.aggregate_reporting_addresses.join(', ')
          }}'; sending reports to yourself can be difficult to understand.
        </ExplainScorePart>
        <ExplainScorePart
          v-else-if="tags.hasOwnProperty('rua=[*]')"
          title="Aggregate Reporting"
          :action-type="ACTION_TYPES.NO_ACTION"
        >
          Reporting Address set to '{{
            result.records.dmarc.aggregate_reporting_addresses.join(', ')
          }}'
        </ExplainScorePart>

        <ExplainScorePart
          v-if="tags.hasOwnProperty('ruf=[]')"
          title="Failure Reporting"
          :action-type="ACTION_TYPES.INFO"
        >
          Reporting Address not specified
        </ExplainScorePart>
        <ExplainScorePart
          v-if="
            result.records.dmarc.failure_reporting_addresses.some((email) =>
              email.toLowerCase().includes(result.domain)
            )
          "
          title="Aggregate Reporting"
          :action-type="ACTION_TYPES.ACTION_RECOMMENDED"
        >
          Reporting Address set to '{{
            result.records.dmarc.failure_reporting_addresses.join(', ')
          }}'; sending reports to yourself can be difficult to understand.
        </ExplainScorePart>

        <ExplainScorePart
          v-else-if="tags.hasOwnProperty('ruf=[*]')"
          title="Failure Reporting"
          :action-type="ACTION_TYPES.NO_ACTION"
        >
          Reporting Address set to '{{
            result.records.dmarc.failure_reporting_addresses.join(', ')
          }}'
        </ExplainScorePart>
      </div>
      <ExplainScorePart
        v-if="
          !result.records.dmarc.dmarc_providers.includes('Unknown') &&
          result.records.dmarc.dmarc_providers.length !== 0
        "
        class="mt-2"
        title="Providers"
        :action-type="ACTION_TYPES.INFO"
      >
        {{ result.records.dmarc.dmarc_providers.join(', ') }}
      </ExplainScorePart>
      <ExplainScorePart
        v-else
        class="mt-2"
        title="Providers"
        :action-type="ACTION_TYPES.INFO"
      >
        <div>
          None -
          <a
            href="https://sendmarc.com/contact/"
            class="underline"
            target="_blank"
          >
            Contact us
          </a>
          for assistance
        </div>
      </ExplainScorePart>
    </div>
  </Card>
</template>
