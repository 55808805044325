//
// Adapted from RFC 2181 (See section 11)
//
// The RFC defines a Domain Name to be at most 255 chars including seperators
// between labels.
// Subdomains are labels plus a seperator. A label is a string starting and
// ending with an alphanum, with dashes allowed in the middle. It is between 1 and 63 chars.
//
// We make some assumptions below that go beyond the RFC. We assume the TLD
// to be at least 2 chars. The regex also allows domains longer than 255 chars,
// but allows at most 127 subdomains, as assuming a lower-bound of one char per
// subdomain will yield 252, and adding a TLD of 2 chars sums to 254.
/*
(?:                   // Group 1: This is for subdomain, which is composed of a label and a seperator (length = [1, 63] + 1)
  [_a-z0-9]
  (?:
    [a-z0-9\-]{0,61} // Limited to 61 chars as we have at least two chars if we reach this group (61 + 2 = 63 which is the limit)
    [a-z0-9]
  )?
  \.                  // Label seperator
){0,126}             // If we assume the lower-bound of 1 char labels, we can at most have 126 groups before approaching the total limit of 255 chars
(?:                   // Group 2: Assume that the TLD is at least 2 chars (for sanity)
  [a-z0-9]
  [a-z0-9\-]{0,61}
  [a-z0-9]
)
\.?                   // Some consider a trailing dot to be considered valid as it signifies the root of the domain tree
 */
const domainNameRegex =
  /^([_a-z0-9][_a-z0-9-]{0,61}[_a-z0-9]?\.){0,126}[_a-z0-9][_a-z0-9-]{0,61}[_a-z0-9]\.?$/i;

/**
 * Test whether a string is a valid domain name, optionally checking for a root dot as well
 * @param  {String}  domainName
 * @return {Boolean}
 */
export function isValidDomain(domainName) {
  if (domainName.length < 2 || domainName.length > 255) return false;
  return domainNameRegex.test(domainName);
}
