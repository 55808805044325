<template>
  <svg
    width="171"
    height="42"
    viewBox="0 0 171 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Sendmarc Logo</title>
    <g clip-path="url(#clip0_727_3199)">
      <path
        d="M6.08765 9.23435C7.75715 8.23528 12.645 5.72188 19.2786 5.72188C23.5952 5.72188 27.1754 6.79007 29.6066 7.808L35.8086 3.89967C32.5521 2.27225 26.6803 0.00390625 19.2786 0.00390625C8.39828 0.00390625 0.825228 4.89874 0 5.45797C0.0126958 12.2818 1.2061 18.691 3.47231 24.2707L8.23959 21.2672C6.90018 17.6417 6.17017 13.5574 6.08765 9.22807V9.23435Z"
        fill="#0073EA"
      />
      <path
        d="M32.0061 14.6635C31.4094 18.5216 30.2414 22.0843 28.5529 25.1633C26.274 29.3166 23.0746 32.5589 19.2722 34.5634C16.2506 32.9674 13.6035 30.5859 11.5024 27.5824L6.72876 30.5922C9.97254 35.6378 14.2701 39.5524 19.2722 41.9967C24.979 39.2069 29.7717 34.5068 33.1297 28.3867C35.9291 23.2845 37.6812 17.2461 38.2969 10.6924L31.9998 14.6573L32.0061 14.6635Z"
        fill="#041221"
      />
      <path
        d="M48.8789 14.1983L49.6978 13.3563C50.212 14.5879 51.691 15.5304 53.57 15.5304C55.2078 15.5304 56.6043 14.8581 56.6043 13.1992C56.6234 11.4587 54.9856 10.8555 53.3161 10.246C51.5577 9.58621 49.7168 8.93272 49.7168 6.89059C49.7168 4.6788 51.5387 3.63574 53.551 3.63574C55.3601 3.63574 56.7567 4.35834 57.5565 5.2883L56.7694 6.08002C56.1917 5.26945 55.0999 4.62853 53.551 4.62853C52.1671 4.62853 50.8912 5.2883 50.8912 6.80262C50.8912 8.23526 52.3068 8.79449 53.8557 9.36628C55.7156 10.0763 57.7787 10.8178 57.7787 13.1489C57.7787 15.4801 55.906 16.6237 53.551 16.6237C51.4371 16.6237 49.666 15.5807 48.8789 14.192V14.1983Z"
        fill="#041221"
      />
      <path
        d="M65.447 4.81731V9.61161H70.4555V10.6421H65.447V15.4364H71.1728V16.4669H64.2853V3.80566H71.1728V4.81731H65.447Z"
        fill="#041221"
      />
      <path
        d="M88.0836 3.80566V16.4606H87.0425L78.9299 5.72841V16.4606H77.7682V3.80566H78.8093L86.941 14.5379V3.80566H88.0836Z"
        fill="#041221"
      />
      <path
        d="M95.0726 3.80566H98.4624C101.992 3.80566 104.855 6.63952 104.855 10.1331C104.855 13.6268 101.992 16.4606 98.4624 16.4606H95.0726V3.80566ZM98.4497 15.4301C101.382 15.4301 103.699 13.0864 103.699 10.1331C103.699 7.1799 101.382 4.81731 98.4497 4.81731H96.2153V15.4301H98.4497Z"
        fill="#041221"
      />
      <path
        d="M123.886 3.80566V16.4606H122.743V5.50849L117.627 13.0864L112.548 5.50849V16.4606H111.387V3.80566H112.599L117.627 11.2139L122.692 3.80566H123.886Z"
        fill="#041221"
      />
      <path
        d="M139.108 13.7084H132.24L131.167 16.4606H129.904L135.033 3.80566H136.309L141.438 16.4606H140.174L139.102 13.7084H139.108ZM138.714 12.7282L135.661 4.99953L132.627 12.7282H138.714Z"
        fill="#041221"
      />
      <path
        d="M155.708 16.4606H154.413L151.258 10.6924H148.617V16.4606H147.456V3.80566H151.905C153.848 3.80566 155.416 5.35769 155.416 7.29928C155.416 9.02096 154.121 10.4222 152.432 10.6421L155.708 16.4606ZM151.874 9.73099C153.258 9.73099 154.362 8.63767 154.362 7.26787C154.362 5.89807 153.239 4.82359 151.874 4.82359H148.617V9.73099H151.874Z"
        fill="#041221"
      />
      <path
        d="M161.078 10.1329C161.078 6.53871 163.687 3.63574 167.318 3.63574C168.715 3.63574 169.997 4.12585 171 4.94899V6.35021C169.959 5.2506 168.715 4.7165 167.318 4.7165C164.284 4.7165 162.17 7.11051 162.17 10.1329C162.17 13.1552 164.284 15.5304 167.318 15.5304C168.715 15.5304 169.997 14.99 171 13.9092V15.2916C169.997 16.121 168.715 16.6237 167.318 16.6237C163.687 16.6237 161.078 13.7207 161.078 10.1266V10.1329Z"
        fill="#041221"
      />
      <path
        d="M85.9766 38.3003C83.3462 38.3003 81.8809 37.0361 81.7397 35.1224H83.9641C84.123 36.1583 84.8644 36.6148 85.9413 36.6148C87.0005 36.6148 87.6008 36.211 87.6008 35.5789C87.6008 34.7186 86.4533 34.6308 85.2175 34.385C83.5933 34.069 82.0045 33.63 82.0045 31.6636C82.0045 29.7147 83.6286 28.7842 85.6941 28.7842C88.0774 28.7842 89.4015 29.9605 89.578 31.7689H87.4066C87.3006 30.8033 86.6828 30.4521 85.6588 30.4521C84.7232 30.4521 84.1053 30.8033 84.1053 31.4529C84.1053 32.2254 85.3058 32.2957 86.5945 32.559C88.1127 32.875 89.7898 33.2964 89.7898 35.4033C89.7898 37.2117 88.2363 38.3003 85.9766 38.3003Z"
        fill="black"
      />
      <path d="M78.323 38.0369V25.4834H80.7239V38.0369H78.323Z" fill="black" />
      <path
        d="M72.4404 38.3003C69.6334 38.3003 67.7092 36.2285 67.7092 33.5422C67.7092 30.856 69.6334 28.7842 72.4404 28.7842C75.2474 28.7842 77.1716 30.856 77.1716 33.5422C77.1716 36.2285 75.2474 38.3003 72.4404 38.3003ZM72.4404 36.4743C73.9057 36.4743 74.7354 35.298 74.7354 33.5422C74.7354 31.7865 73.9057 30.5926 72.4404 30.5926C70.9575 30.5926 70.1454 31.7865 70.1454 33.5422C70.1454 35.298 70.9575 36.4743 72.4404 36.4743Z"
        fill="black"
      />
      <path
        d="M62.3033 38.3003C59.4963 38.3003 57.572 36.2285 57.572 33.5422C57.572 30.856 59.4963 28.7842 62.3033 28.7842C65.1102 28.7842 67.0345 30.856 67.0345 33.5422C67.0345 36.2285 65.1102 38.3003 62.3033 38.3003ZM62.3033 36.4743C63.7685 36.4743 64.5983 35.298 64.5983 33.5422C64.5983 31.7865 63.7685 30.5926 62.3033 30.5926C60.8203 30.5926 60.0083 31.7865 60.0083 33.5422C60.0083 35.298 60.8203 36.4743 62.3033 36.4743Z"
        fill="black"
      />
      <path
        d="M48.7964 27.6254V25.4834H58.9473V27.6254H55.1518V38.0369H52.592V27.6254H48.7964Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_727_3199">
        <rect width="171" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
