<script setup>
import Card from '@/Components/Card/Card.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import { reactive } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { isValidDomain } from '@/Common/IsValidDomain.js';
import DomainInput from '@/Pages/Common/DomainInput.vue';

const form = useForm({});

const props = defineProps({
  domain: {
    type: String,
    required: false
  }
});

const state = reactive({
  domainName: props.domain ?? '',
  errors: {}
});

const submit = () => {
  state.errors = {};
  if (!isValidDomain(state.domainName)) {
    state.errors['domainName'] = 'Enter a Valid Domain Name';
  }

  if (Object.keys(state.errors).length > 0) {
    return false;
  }

  form.get(route('domain-analysis.show', [state.domainName]));
};
</script>

<template>
  <Card>
    <form @submit.prevent="submit">
      <div
        class="flex flex-col items-start justify-between gap-4 md:flex-row md:items-center"
      >
        <DomainInput
          v-model="state.domainName"
          redirect-to="domain-analysis.show"
          :error-message="state.errors.domainName"
          class="flex w-full flex-1"
          required
        />
        <div class="mt-2">
          <PrimaryButton size="large" :is-loading="form.processing">
            Analyze Domain
          </PrimaryButton>
        </div>
      </div>
    </form>
  </Card>
</template>
