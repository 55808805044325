<script setup>
import StatusIcon from '@/Pages/Common/StatusIcon.vue';
import ActionType, {
  getActionTypeFromStatus
} from '@/Common/Enum/ActionType.js';
import { NIGHTCRAWLER_CHECK_STATUS } from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';
import ExplainDirectiveRow from '@/Pages/DomainAnalysis/Partials/ExplainDirectiveRow.vue';
import translateNightCrawlerCode from '@/Common/Classifiers/NightCrawlerTranslations.js';

const { directive } = defineProps({
  title: String,
  part: String,
  directive: {
    type: Object,
    default: null
  },
  infoText: {
    type: String,
    default: null
  }
});

const tooltip = (() => {
  if (!directive) {
    return 'No Errors Found';
  }

  const messages = directive.checks.reduce((acc, check) => {
    if (check.status !== NIGHTCRAWLER_CHECK_STATUS.OK) {
      acc.push(translateNightCrawlerCode(check));
    }
    return acc;
  }, []);

  return !messages.length ? 'No Errors Found' : messages.join('\n');
})();
</script>

<template>
  <ExplainDirectiveRow v-if="directive !== null || infoText !== null">
    <div>{{ title }}</div>
    <div class="hidden md:block">{{ part }}</div>
    <template #detail>
      <div
        class="text-wrap break-all"
        :class="{
          'font-light italic text-cool-grey-800': directive === null
        }"
      >
        {{ directive?.value ?? infoText }}
        <slot name="detail"></slot>
      </div>
    </template>
    <template #icon>
      <StatusIcon
        v-if="!directive"
        v-tooltip="'Information'"
        :action-type="ActionType.INFO"
      />
      <StatusIcon
        v-else
        v-tooltip="tooltip"
        :action-type="getActionTypeFromStatus(directive.status)"
      />
    </template>
  </ExplainDirectiveRow>
</template>
