<script setup>
import ACTION_TYPES, {
  getActionTypeFromScore
} from '@/Common/Enum/ActionType.js';

const props = defineProps({
  score: Number
});

const actionType = getActionTypeFromScore(props.score);
</script>

<template>
  <div class="mt-4">
    <p v-if="actionType === ACTION_TYPES.NO_ACTION">
      Email from your domain is highly secure from impersonation and
      interception and recipients will see your branding in communication. These
      measures build trust in your brand, protect recipients from attacks and
      help ensure email is delivered effectively.
    </p>
    <p v-else-if="actionType === ACTION_TYPES.ACTION_RECOMMENDED">
      You have some measures in place to protect recipients from malicious email
      from your domain. Attention should be paid to areas where controls can be
      improved. These measures build trust in your brand, protect recipients
      from attacks and help ensure email is delivered effectively.
    </p>
    <p v-else>
      You don't have effective controls in place to protect your domain from
      impersonation and interception of email communication. This puts your
      brand and email recipients at risk of attacks, reduces trust, and can
      damage email deliverability.
    </p>
  </div>
</template>
