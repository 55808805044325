<script setup>
import { reactive } from 'vue';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';

const props = defineProps({
  expandable: {
    type: Boolean,
    default: true
  },
  expanded: Boolean
});

const state = reactive({
  isExpanded: props.expanded
});
</script>

<template>
  <div>
    <div
      :class="{ 'cursor-pointer': expandable }"
      class="text-pretty rounded-3xl bg-navy-400 px-6 py-4 text-sm text-white-smoke-500 transition duration-300 hover:text-blue-200 md:px-8"
      @click="expandable ? (state.isExpanded = !state.isExpanded) : false"
    >
      <div class="flex items-start justify-between gap-3">
        <IconExpandCircleDown
          v-if="expandable"
          class="shrink-0 transform cursor-pointer transition duration-300 ease-in-out"
          :class="{ 'rotate-180': state.isExpanded }"
        />
        <div class="flex-1 truncate pt-0.5 text-white-smoke-500">
          <slot :expanded="state.isExpanded" />
        </div>
      </div>
    </div>
    <div v-if="expandable && state.isExpanded">
      <slot name="expandable" />
    </div>
  </div>
</template>
