<script setup>
import WebLayout from '@/Layouts/WebLayout.vue';
import PdfLayout from '@/Layouts/PdfLayout.vue';
import { inject } from 'vue';

defineProps({
  title: String
});

const layout = inject('layout');
</script>

<template>
  <PdfLayout v-if="layout === 'pdf'" :title="title">
    <slot />
  </PdfLayout>
  <WebLayout v-else :title="title">
    <slot />
  </WebLayout>
</template>
