<script setup>
import HostNameInput from '@/Components/Input/HostNameInput.vue';
import InputDescription from '@/Components/Input/InputDescription.vue';
import { Link } from '@inertiajs/vue3';
import { computed, reactive } from 'vue';

const props = defineProps({
  modelValue: String,
  errorMessage: {
    type: [String, Boolean],
    default: null
  },
  redirectTo: {
    type: String,
    required: true
  }
});

const state = reactive({
  domainName: props.modelValue
});

const domainName = computed(() => props.modelValue.replace(/^www\./, ''));

const containsWww = computed(() => {
  return (
    props.modelValue.toLowerCase().startsWith('www.') &&
    props.modelValue.length > 5
  );
});

const variant = computed(() => {
  if (props.errorMessage !== null) {
    return 'danger';
  }
  return containsWww.value ? 'warning' : 'default';
});
</script>

<template>
  <div class="flex flex-col">
    <HostNameInput
      id="domain"
      label="Domain Name"
      v-model.trim.lazy="state.domainName"
      size="large"
      clearable
      autofocus
      v-bind="$attrs"
      :variant="variant"
    />
    <InputDescription :variant="variant">
      <div v-if="errorMessage !== null">
        {{ errorMessage }}
      </div>
      <div v-else-if="containsWww">
        Using 'www' indicates a subdomain, would you like to use
        <Link
          :href="route(redirectTo, domainName)"
          class="text-blue-600 underline hover:text-blue-500"
        >
          {{ domainName }}
        </Link>
        instead?
      </div>
      <div v-else>e.g. example.com</div>
    </InputDescription>
  </div>
</template>
