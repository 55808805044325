<script setup>
import { reactive } from 'vue';
import StatusIcon from '@/Pages/Common/StatusIcon.vue';
import { getActionTypeFromStatus } from '@/Common/Enum/ActionType.js';
import IconExpandCircleDown from '@/Components/Icons/IconExpandCircleDown.vue';
import LinkOpenInNew from '@/Components/LinkOpenInNew.vue';

defineProps({
  node: Object,
  root: Boolean,
  ip: {
    type: [String, Boolean],
    default: false
  }
});

const state = reactive({
  closed: []
});

const toggle = (domain) => {
  const index = state.closed.indexOf(domain);
  if (index === -1) {
    state.closed.push(domain);
  } else {
    state.closed.splice(index, 1);
  }
};

const getNodeValue = (node) => {
  if (Array.isArray(node.record)) {
    return node.record.length === 0
      ? 'Unable to Retrieve'
      : node.record.join(', ');
  }
  return node.record;
};

const getRoute = (node) => {
  if (node.record_type === 'SPF')
    return route('explain.show', ['spf', node.domain]);
  if (node.record_type === 'A') return route('lookup.show', ['a', node.domain]);
  if (node.record_type === 'MX')
    return route('lookup.show', ['mx', node.domain]);
  if (node.record_type === 'PTR')
    return route('explain.show', ['spf', node.domain]);
};
</script>

<template>
  <div class="-m-4">
    <div class="mb-1 flex items-center justify-between gap-2">
      <div class="flex flex-wrap items-center gap-2">
        <StatusIcon :action-type="getActionTypeFromStatus(node.status)" />
        <div class="font-bold">{{ node.record_type }}:</div>
        {{ node.domain }}
      </div>
      <LinkOpenInNew class="text-sm" :href="getRoute(node)">
        Analyze
      </LinkOpenInNew>
    </div>
    <div
      class="relative z-10 text-pretty rounded-3xl bg-navy-400 p-4 text-sm text-white-smoke-500 transition duration-300 hover:text-blue-200"
      :class="{
        'ring-4 ring-purple-500': ip && node.record.includes(ip),
        'cursor-pointer': node.children.length,
        'bg-navy-400/50 pl-5 !font-normal italic text-white-smoke-500':
          node.record.length === 0
      }"
      @click="toggle(node.domain)"
    >
      <div class="flex flex-1 items-center gap-3">
        <IconExpandCircleDown
          v-if="node.children.length"
          class="shrink-0 transform cursor-pointer transition-transform duration-300 ease-in-out"
          :class="{ 'rotate-180': !state.closed.includes(node.domain) }"
        />
        <div class="flex flex-1 font-mono text-white-smoke-500">
          {{ getNodeValue(node) }}
        </div>
      </div>
    </div>
  </div>
  <ul
    v-if="
      node.children &&
      node.children.length &&
      !state.closed.includes(node.domain)
    "
  >
    <li v-for="childNode in node.children" :key="childNode.domain">
      <SpfTree :key="childNode.domain" :node="childNode" :ip="ip" />
    </li>
  </ul>
</template>

<style>
.treeview {
  margin: 0 1rem 1rem 1rem;
}

.treeview > ul > li:first-child {
  padding: 16px 0 0 0;
}

.treeview ul li {
  padding: 60px 0 0 32px;
  position: relative;
}
.treeview ul li:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-left: 1px solid #041221;
  width: 1px;
  height: 100%;
  z-index: 0;
}
.treeview ul li:after {
  content: '';
  position: absolute;
  border-top: 1px solid #041221;
  top: 55px;
  left: 0;
  width: 12px;
}
.treeview ul li:last-child:before {
  height: 56px;
}
.treeview > ul > li:after,
.treeview > ul > li:last-child:before {
  content: unset;
}
</style>
