<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';

const props = defineProps({
  align: {
    type: String,
    default: 'right'
  },
  contentClasses: {
    type: Array,
    default: () => ['py-1', 'md:bg-white']
  }
});

let open = ref(false);

const closeOnEscape = (e) => {
  if (open.value && e.key === 'Escape') {
    open.value = false;
  }
};

onMounted(() => document.addEventListener('keydown', closeOnEscape));
onUnmounted(() => document.removeEventListener('keydown', closeOnEscape));

const alignmentClasses = computed(() => {
  if (props.align === 'left') {
    return 'ltr:origin-top-left rtl:origin-top-right start-0';
  }

  if (props.align === 'right') {
    return 'ltr:origin-top-right rtl:origin-top-left end-0';
  }

  return 'origin-top';
});
</script>

<template>
  <div class="relative">
    <div @click="open = !open">
      <slot name="trigger" />
    </div>

    <!-- Full Screen Dropdown Overlay -->
    <div v-show="open" class="z-50 md:fixed md:inset-0" @click="open = false" />

    <transition
      enter-active-class="transition ease-out duration-200"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="open"
        class="z-50 mt-2 flex flex-row items-center md:absolute md:rounded-sm md:shadow-sm"
        :class="[alignmentClasses]"
        style="display: none"
        @click="open = false"
      >
        <div
          class="w-full md:rounded-md md:ring-1 md:ring-black md:ring-opacity-5"
          :class="contentClasses"
        >
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>
