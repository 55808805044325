import { API_STATUS } from '@/Common/Enum/ApiStatus.js';
import { NIGHTCRAWLER_CHECK_STATUS } from '@/Common/Classifiers/NightcrawlerCheckClassifier.js';

export const ACTION_TYPES = {
  ACTION_REQUIRED: {
    color: 'danger',
    icon: 'cross',
    tooltip: 'Action is Required'
  },
  ACTION_RECOMMENDED: {
    color: 'warning',
    icon: 'exclaim',
    tooltip: 'Action is Recommended'
  },
  ACTION_OPTIONAL: {
    color: 'info',
    icon: 'info',
    tooltip: 'Not Required'
  },
  NO_ACTION: { color: 'success', icon: 'check', tooltip: 'No Errors Found' },
  NO_ACTION_WITH_DEFAULT: {
    color: 'success',
    icon: 'check',
    tooltip: 'Not Required, Default Value Applied'
  },
  INFO: { color: 'info', icon: 'info', tooltip: 'Information' },
  INFO_WARN: { color: 'warning', icon: 'info', tooltip: 'Information' },
  INFO_ERROR: { color: 'danger', icon: 'info', tooltip: 'Information' },
  INFO_DEFAULT: { color: 'default', icon: 'info', tooltip: 'Information' }
};

export function getActionTypeFromStatus(status) {
  switch (status) {
    case API_STATUS.OK:
    case NIGHTCRAWLER_CHECK_STATUS.OK:
      return ACTION_TYPES.NO_ACTION;
    case API_STATUS.INFO:
    case NIGHTCRAWLER_CHECK_STATUS.INFO:
      return ACTION_TYPES.INFO;
    case API_STATUS.WARN:
    case NIGHTCRAWLER_CHECK_STATUS.WARN:
      return ACTION_TYPES.ACTION_RECOMMENDED;
    case API_STATUS.FAIL:
    case API_STATUS.ERROR:
    case API_STATUS.FATAL:
    case NIGHTCRAWLER_CHECK_STATUS.ERROR:
    case NIGHTCRAWLER_CHECK_STATUS.FATAL:
      return ACTION_TYPES.ACTION_REQUIRED;
  }
}

export function getActionTypeFromScore(score) {
  if (score >= 80) {
    return ACTION_TYPES.NO_ACTION;
  } else if (score >= 50) {
    return ACTION_TYPES.ACTION_RECOMMENDED;
  } else {
    return ACTION_TYPES.ACTION_REQUIRED;
  }
}

export function getActionTypeFromRating(rating) {
  if (rating < 4) {
    return ACTION_TYPES.ACTION_REQUIRED;
  } else if (rating < 5) {
    return ACTION_TYPES.ACTION_RECOMMENDED;
  } else {
    return ACTION_TYPES.NO_ACTION;
  }
}

export default ACTION_TYPES;
