<script setup>
import ACTION_TYPES from '@/Common/Enum/ActionType.js';
import StatusIcon from '@/Pages/Common/StatusIcon.vue';

const { result } = defineProps({
  result: String,
  label: String
});

const type = (input) => {
  switch (input.toLowerCase()) {
    case 'pass':
      return ACTION_TYPES.NO_ACTION;
    case 'fail':
      return ACTION_TYPES.ACTION_REQUIRED;
    case 'softfail':
      return ACTION_TYPES.ACTION_RECOMMENDED;
    case 'none':
    case 'policy':
    case 'neutral':
      return ACTION_TYPES.INFO;
    case 'skipped':
    case 'temperror':
      return ACTION_TYPES.INFO_WARN;
    case 'permerror':
      return ACTION_TYPES.INFO_ERROR;
  }
};

const fmt = (input) => {
  switch (input.toLowerCase()) {
    case 'temperror':
      return 'Temporary Error';
    case 'permerror':
      return 'Permanent Error';
    default:
      return input;
  }
};
</script>

<template>
  <div class="flex items-center gap-1">
    <StatusIcon :action-type="type(result)" />
    {{ label ?? fmt(result) }}
  </div>
</template>
