<script setup>
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import Card from '@/Components/Card/Card.vue';
import TextInput from '@/Components/Input/TextInput.vue';
import { reactive } from 'vue';
import { router } from '@inertiajs/vue3';
import isValidIP from '@/Common/IsValidIp.js';
import isValidEmail from '@/Common/IsValidEmail.js';

const props = defineProps({
  email: {
    type: String,
    default: '',
    required: false
  },
  ip: {
    type: String,
    default: '',
    required: false
  }
});

const state = reactive({
  emailAddress: props.email,
  ipAddress: props.ip,
  isLoading: false,
  errors: {}
});

const submit = () => {
  state.errors = {};
  if (!isValidEmail(state.emailAddress)) {
    state.errors['emailAddress'] = 'Enter a Valid Email Address';
  }

  if (!isValidIP(state.ipAddress)) {
    state.errors['ipAddress'] = 'Enter a Valid IP Address';
  }

  if (Object.keys(state.errors).length > 0) {
    return false;
  }
  state.isLoading = true;
  router.get(
    route('spf-policy-test.show', [state.emailAddress, state.ipAddress])
  );
};
</script>

<template>
  <Card>
    <div class="mb-5">
      Check if a sender IP address is authorized, this achieved by looking up
      SPF record and ensuring the sending IP address is present in the SPF
      record.
    </div>
    <form @submit.prevent="submit">
      <div class="flex flex-col gap-4 md:flex-row md:items-center">
        <TextInput
          v-model.trim="state.emailAddress"
          size="large"
          clearable
          required
          label="Email Address"
          :description="state.errors?.emailAddress ?? 'e.g. info@example.com'"
          class="md:mr-5"
          :variant="
            state.errors.hasOwnProperty('emailAddress') ? 'danger' : 'default'
          "
        />
        <TextInput
          v-model.trim="state.ipAddress"
          size="large"
          clearable
          required
          label="IP Address"
          :description="state.errors?.ipAddress ?? 'e.g. 127.0.0.1'"
          :variant="
            state.errors.hasOwnProperty('ipAddress') ? 'danger' : 'default'
          "
        />
        <div class="flex">
          <PrimaryButton
            size="large"
            :is-loading="state.isLoading"
            class="mt-2"
          >
            Run Policy SPF test
          </PrimaryButton>
        </div>
      </div>
    </form>
  </Card>
</template>
