import VueTooltip from 'floating-vue';

export const tooltipSetup = (app) => {
  app.use(VueTooltip, {
    container: false,
    themes: {
      // Override default theme
      tooltip: {
        ...VueTooltip.options.themes.tooltip,
        triggers: ['hover', 'click', 'touch'],
        autoHide: true,
        eagerMount: false,
        placement: 'top',
        distance: 9,
        instantMove: true,
        handleResize: true,
        html: true,
        preventOverflow: true
      }
    }
  });
};
