<script setup>
import SiteLayout from '@/Layouts/Layout.vue';
import Card from '@/Components/Card/Card.vue';
import PrimaryButton from '@/Components/Button/PrimaryButton.vue';
import KnowYourScore from '@/Pages/DomainScore/Partials/KnowYourScore.vue';
import { useForm } from '@inertiajs/vue3';
import { reactive } from 'vue';
import { isValidDomain } from '@/Common/IsValidDomain.js';
import DomainInput from '@/Pages/Common/DomainInput.vue';

const form = useForm({});

const state = reactive({
  domain: '',
  error: null
});

const scoreDomain = () => {
  state.error = null;

  if (!isValidDomain(state.domain)) {
    state.error = 'Enter a Valid Domain Name';
    return;
  }
  form.get(route('score.show', state.domain));
};
</script>

<template>
  <SiteLayout title="Know Your Score">
    <Card class="text-center md:py-10">
      <form @submit.prevent="scoreDomain">
        <div class="mx-auto lg:max-w-[800px]">
          <DomainInput
            v-model="state.domain"
            required
            redirect-to="score.show"
            :error-message="state.error"
            label-classes="!mb-2 text-base text-xl md:text-3xl"
            class="w-full flex-grow text-center"
          />
        </div>
        <PrimaryButton class="mt-5" size="large" :is-loading="form.processing">
          Get Your Domain Score
        </PrimaryButton>
      </form>
    </Card>
    <KnowYourScore />
  </SiteLayout>
</template>
